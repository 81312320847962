<template>
    <v-main>
        <div
            class="cookie-notification"
            v-if="cookieShow"
        >
            <p>
                Этот сайт использует cookie и другие технологии для сбора информации технического характера. Подробнее о
                <router-link :to="{name: 'Cookies'}" target="_blank">
                    файлах cookie
                </router-link>
            </p>
            <v-btn
                color="gray"
                class="cookie-accept"
                @click="cookieAccept"
            >
                Хорошо
            </v-btn>
        </div>
        <section id="hero">
            <v-row no-gutters>
                <v-container
                    id="leedbot"
                    tag="section"
                    class="leedbot"
                >
                    <v-row
                        justify="center"
                    >
                        <v-col
                            cols="12"
                            xl="6"
                            lg="8"
                            md="8"
                            sm="10"
                            style="padding-top: 5%"
                            class="main-col"
                        >
                            <v-card
                                class="v-card-profile v-card--material pa-3"
                            >
                                <div class="d-flex grow flex-wrap">
                                    <div
                                        class="v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                                        style="height: 128px; min-width: 128px; width: 128px"
                                    >
                                        <div class="v-image v-responsive theme--light">
                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                 alt=""
                                            >
                                            <div class="v-responsive__content" style="width: 250px"></div>
                                        </div>
                                    </div>

                                    <v-card-text class="text-center">
                                        <h4 class="display-2 font-weight-light mb-3 black--text">
                                            {{ userData.name }} {{ userData.last_name }}
                                        </h4>

                                        <h6
                                            class="display-1 mb-3 green--text"
                                            v-if="userData.who_is"
                                        >
                                            <v-icon class="mr-2 green--text">mdi-seal-variant</v-icon>
                                            {{ userData.who_is }}
                                        </h6>
                                    </v-card-text>
                                </div>
                                <v-divider/>
                                <v-row>
                                    <v-col class="">
                                        <div class="chat-list">
                                            <div>
                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesFirst"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime1 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div v-if="userData.about_me" class="chat-message">
                                                                    <span v-html="userData.about_me"></span>
                                                                </div>
                                                                <div v-else class="chat-message">
                                                                    <p>
                                                                        Привет, на связи {{ userData.name }} {{ userData.last_name }}, я предприниматель!
                                                                    </p>
                                                                    <p>
                                                                        Развиваю свой бизнес в партнёрстве с немецким производителем LR в направлении здоровья и красоты
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesSecond"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime1 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Выберите, о чем хотите узнать подробнее, и мой виртуальный помощник расскажет об этом.
                                                                        На изучение информации понадобится чуть больше 10 минут. В конце вас ждет ценный подарок &#128521;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    v-if="userData.id != 127"
                                                                    class="chat-btn"
                                                                    :disabled="disBtnBiz"
                                                                    @click="btnBiz"
                                                                >
                                                                    Расскажи как заработать
                                                                </v-btn>
                                                                <v-btn
                                                                    v-if="userData.id != 127"
                                                                    class="chat-btn"
                                                                    :disabled="disBtnProd"
                                                                    @click="btnProd"
                                                                >
                                                                    Расскажи про продукт
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answBizProd"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime2 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div v-if="clientData.branch === 'бизнес'" class="chat-message">
                                                                    Расскажи как заработать
                                                                </div>
                                                                <div v-if="clientData.branch === 'продукт'" class="chat-message">
                                                                    Расскажи про продукт
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz1"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime2 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Давайте для начала познакомимся &#128521;
                                                                    </p>
                                                                    <p>
                                                                        Как вас зовут?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-input">
                                                                <v-text-field
                                                                    :disabled="disInpName"
                                                                    label="Введите свое имя"
                                                                    type="text"
                                                                    v-model.trim="clientData.name"
                                                                    :error-messages="nameErrors"
                                                                    @input="$v.clientData.name.$touch()"
                                                                    @blur="$v.clientData.name.$touch()"
                                                                />
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnName"
                                                                    @click="myName"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Меня зовут
                                                                    <span
                                                                        class="ml-2"
                                                                        style="text-decoration: underline"
                                                                    >
                                                                        {{ clientData.name }}
                                                                    </span>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answName"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime3 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Меня зовут {{ clientData.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz2"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime3 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Приятно познакомиться, {{ clientData.name }}!
                                                                    </p>
                                                                    <p>
                                                                        Ответьте, пожалуйста, еще на несколько вопросов, чтобы мой виртуальный помощник
                                                                        подобрал информацию актуальную именно для вас &#128071;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnOk1"
                                                                    @click="btnOk1"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Хорошо
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answOk1"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime4 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Хорошо
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz3"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime4 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Ваш пол?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    color="green"
                                                                    :disabled="disBtnM"
                                                                    @click="btnM"
                                                                >
                                                                    М
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    color="green"
                                                                    :disabled="disBtnG"
                                                                    @click="btnG"
                                                                >
                                                                    Ж
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answGender"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime5 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    {{ clientData.gender }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz4"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime5 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Сколько вам лет?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-input">
                                                                <v-text-field
                                                                    :disabled="disInpAge"
                                                                    label="Введите число полных лет"
                                                                    type="text"
                                                                    v-model.trim="clientData.age"
                                                                    :error-messages="ageErrors"
                                                                    @input="$v.clientData.age.$touch()"
                                                                    @blur="$v.clientData.age.$touch()"
                                                                />
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnAge"
                                                                    @click="myAge"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Мне
                                                                    <span
                                                                        class="ml-2"
                                                                        style="text-decoration: underline"
                                                                    >
                                                                        {{ clientData.age }}
                                                                    </span>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answAge"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime6 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Мне {{ clientData.age }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz5"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime6 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Чем вы занимаетесь?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnNaim"
                                                                    @click="targetNaim"
                                                                >
                                                                    Работаю по найму
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnFree"
                                                                    @click="targetFree"
                                                                >
                                                                    Работаю на себя
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnEnt"
                                                                    @click="targetEnt"
                                                                >
                                                                    Свой бизнес
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnPfr"
                                                                    @click="targetPfr"
                                                                >
                                                                    На пенсии
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnMlm"
                                                                    @click="targetMlm"
                                                                >
                                                                    Сетевик
                                                                </v-btn>
                                                                <v-btn
                                                                    v-if="clientData.gender === 'ж'"
                                                                    class="chat-btn"
                                                                    :disabled="disBtnDekret"
                                                                    @click="targetDekret"
                                                                >
                                                                    В декрете
                                                                </v-btn>
                                                                <v-btn
                                                                    v-if="clientData.gender === 'ж'"
                                                                    class="chat-btn"
                                                                    :disabled="disBtnDom"
                                                                    @click="targetDom"
                                                                >
                                                                    Домохозяйка
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answTarget"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    {{ clientData.target_a }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesNaim1"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Да, есть у меня опыт работы по найму.
                                                                        Постоянный напряг по деньгам, жизнь по будильнику, долги, кредиты...
                                                                    </p>
                                                                    <p>
                                                                        Каждый день как день сурка: дом - работа, дом - работа. И никакой свободы! &#128543;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesNaim2"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Представьте, какое чувство свободы испытывает человек, которому не нужно вставать
                                                                        по будильнику и перед кем-то отчитываться.
                                                                    </p>
                                                                    <p>
                                                                        Когда все расходы и платежи уже оплачены, а еще только половина месяца прошла.
                                                                        При этом остались свободные деньги, и вы не знаете, куда их потратить. &#129395;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesNaim3"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        {{ clientData.name }}, какой доход позволит вам начать жить такой жизнью?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-input">
                                                                <v-text-field
                                                                    :disabled="disInpMoney"
                                                                    label="Сколько хотите зарабатывать?"
                                                                    type="text"
                                                                    v-model.trim="clientData.income_want"
                                                                    :error-messages="moneyErrors"
                                                                    @input="$v.clientData.income_want.$touch()"
                                                                    @blur="$v.clientData.income_want.$touch()"
                                                                />
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnMoney"
                                                                    @click="btnMoney"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Хочу получать
                                                                    <span
                                                                        class="ml-2"
                                                                        style="text-decoration: underline"
                                                                    >
                                                                        {{ clientData.income_want }}
                                                                    </span>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesEnt1"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Респект! Предприниматели - это люди с твердым характером.
                                                                    </p>
                                                                    <p>
                                                                        Не каждый готов в одиночку тащить на себе бизнес, платить аренду, когда нет выручки,
                                                                        постоянно искать новых сотрудников, видеть деньги только на бумаге,
                                                                        вместо выходного с семьей решать бесконечные проблемы в бизнесе,
                                                                        а про спокойный отпуск я вообще молчу... &#128543;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesEnt2"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        А что если бизнес может быть другим?
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Просто представьте, что у вас появилась возможность систематизировать все
                                                                        бизнес-процессы и скинуть с себя всю операционку, аренду, найм персонала,
                                                                        хранение товара, логистику, бухгалтерию и прочие сложности.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        И теперь вы можете сфокусироваться только на масштабировании бизнеса
                                                                        и увеличении прибыли.
                                                                    </p>
                                                                    <p>
                                                                        Это позволит высвободить время для себя, семьи, хобби, ощутить свободу и уверенность.
                                                                        &#129395;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesEnt3"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        {{ clientData.name }}, какой доход вы хотели бы получать от бизнеса?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-input">
                                                                <v-text-field
                                                                    :disabled="disInpMoney"
                                                                    label="Сколько хотите зарабатывать?"
                                                                    type="text"
                                                                    v-model.trim="clientData.income_want"
                                                                    :error-messages="moneyErrors"
                                                                    @input="$v.clientData.income_want.$touch()"
                                                                    @blur="$v.clientData.income_want.$touch()"
                                                                />
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnMoney"
                                                                    @click="btnMoney"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Хочу получать
                                                                    <span
                                                                        class="ml-2"
                                                                        style="text-decoration: underline"
                                                                    >
                                                                        {{ clientData.income_want }}
                                                                    </span>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesFree1"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Респект! В работе на себя есть много преимуществ.
                                                                        Нет начальника, сам выбираешь когда и сколько работать.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Однако есть и свои нюансы.
                                                                    </p>
                                                                    <p>
                                                                        Постоянный поиск клиентов. Как потопал - так и полопал, как говорится.
                                                                        Работаешь практически всегда и много...
                                                                        Клиенты сегодня есть, а завтра - ушли к конкурентам.
                                                                        &#128543;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesFree2"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        А что если при таких же усилиях вы сможете создать свое дело,
                                                                        которое будет приносить доход и без вашего участия?
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Один раз поработал и получаешь доход постоянно. Многие процессы автоматизированы,
                                                                        поэтому сможете высвободить время для себя, семьи, хобби, ощутить свободу и уверенность.
                                                                        &#129395;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesFree3"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        {{ clientData.name }}, какой доход вы хотели бы получать от своей деятельности?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-input">
                                                                <v-text-field
                                                                    :disabled="disInpMoney"
                                                                    label="Сколько хотите зарабатывать?"
                                                                    type="text"
                                                                    v-model.trim="clientData.income_want"
                                                                    :error-messages="moneyErrors"
                                                                    @input="$v.clientData.income_want.$touch()"
                                                                    @blur="$v.clientData.income_want.$touch()"
                                                                />
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnMoney"
                                                                    @click="btnMoney"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Хочу получать
                                                                    <span
                                                                        class="ml-2"
                                                                        style="text-decoration: underline"
                                                                    >
                                                                        {{ clientData.income_want }}
                                                                    </span>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesDekret1"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Респект! Мама в декрете - это, наверное, самая сложная профессия, причем чаще всего
                                                                        близкие нам люди этого не осознают.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Сколько сил и терпения нужно иметь, чтобы переносить постоянный недосып, не сойти
                                                                        с ума в бесконечном дне сурка: стирка, глажка, готовка, уборка...
                                                                    </p>
                                                                    <p>
                                                                        Времени на себя практически нет: элементарно принять ванну и побыть одной
                                                                        - непозволительная роскошь. &#128543;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesDekret2"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Есть проверенный способ, который может в корне изменить ситуацию.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Просто представьте, какие чувства вы будете испытывать, когда появится
                                                                        СВОЙ источник дохода.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Не зависеть от мужа, нанять няню, чаще посещать салоны красоты,
                                                                        покупать себе и своему ребенку одежду, которая нравится, заниматься своим развитием, начать
                                                                        ходить в спортзал...
                                                                    </p>
                                                                    <p>
                                                                        И самое главное - не нужно выходить на работу после декрета! &#129395;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesDekret3"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        {{ clientData.name }}, какой доход позволил бы вам начать жить такой жизнью?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-input">
                                                                <v-text-field
                                                                    :disabled="disInpMoney"
                                                                    label="Сколько хотите зарабатывать?"
                                                                    type="text"
                                                                    v-model.trim="clientData.income_want"
                                                                    :error-messages="moneyErrors"
                                                                    @input="$v.clientData.income_want.$touch()"
                                                                    @blur="$v.clientData.income_want.$touch()"
                                                                />
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnMoney"
                                                                    @click="btnMoney"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Хочу получать
                                                                    <span
                                                                        class="ml-2"
                                                                        style="text-decoration: underline"
                                                                    >
                                                                        {{ clientData.income_want }}
                                                                    </span>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesMlm1"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Респект! Сетевой - это бизнес для смелых людей, движимых мечтой и большими целями.
                                                                    </p>
                                                                    <p>
                                                                        В теории - это идеальный бизнес, в котором может преуспеть каждый.
                                                                        Но по факту огромное количество людей топчутся на месте. &#128543;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesMlm2"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Если нет инструментов для демонстрации бизнеса и продукта
                                                                        - получаешь много отказов, денег нет, выглядишь глупо в глазах близких и знакомых.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Если нет простой системы обучения партнеров - бизнес превращается в проходной двор
                                                                        - пришел один, а ушло 10. Вечный вопрос: "Где взять новых людей?"
                                                                    </p>
                                                                    <p>
                                                                        Если вы чувствуете, что в вашей компании вам сложно двигаться
                                                                        к желаемым целям, то могу показать, как с помощью нашей системы за пару месяцев
                                                                        можно выйти на доходы от 18 000 рублей (250 евро), а в течение года - на сотку.
                                                                        &#129395;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesMlm3"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        {{ clientData.name }}, сколько хотите зарабатывать в сетевом бизнесе?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-input">
                                                                <v-text-field
                                                                    :disabled="disInpMoney"
                                                                    label="Сколько хотите зарабатывать?"
                                                                    type="text"
                                                                    v-model.trim="clientData.income_want"
                                                                    :error-messages="moneyErrors"
                                                                    @input="$v.clientData.income_want.$touch()"
                                                                    @blur="$v.clientData.income_want.$touch()"
                                                                />
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnMoney"
                                                                    @click="btnMoney"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Хочу получать
                                                                    <span
                                                                        class="ml-2"
                                                                        style="text-decoration: underline"
                                                                    >
                                                                        {{ clientData.income_want }}
                                                                    </span>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesPfr1"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        С одной стороны, пенсия - это хорошо, есть много свободного времени.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Но с другой - жизнь на пенсии, в основном, скучновата и однообразна, ограничена и вызывает
                                                                        чувство одиночества.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        А хочется быть в интересном сообществе, общаться, больше путешествовать, пожить для себя.
                                                                    </p>
                                                                    <p>
                                                                        Понятно, что денег не достаточно. Здоровье также оставляет желать лучшего. &#128543;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesPfr2"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Есть проверенный способ, который может в корне изменить ситуацию.
                                                                    </p>
                                                                    <p>
                                                                        В нашей команде много людей, которые без особых навыков, будучи на пенсии, смогли создать
                                                                        хороший дополнительный доход и существенно поправили свое здоровье.
                                                                        &#129395;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesPfr3"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        {{ clientData.name }}, какой дополнительный доход позволит вам достойно жить на пенсии?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-input">
                                                                <v-text-field
                                                                    :disabled="disInpMoney"
                                                                    label="Сколько хотите зарабатывать?"
                                                                    type="text"
                                                                    v-model.trim="clientData.income_want"
                                                                    :error-messages="moneyErrors"
                                                                    @input="$v.clientData.income_want.$touch()"
                                                                    @blur="$v.clientData.income_want.$touch()"
                                                                />
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnMoney"
                                                                    @click="btnMoney"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Хочу получать
                                                                    <span
                                                                        class="ml-2"
                                                                        style="text-decoration: underline"
                                                                    >
                                                                        {{ clientData.income_want }}
                                                                    </span>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesDom1"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Образ домохозяйки для многих выглядит так: ты сидишь дома,
                                                                        у тебя куча свободного времени, муж обеспечивает, занимайся семьей, домом,
                                                                        живи и радуйся.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        И, действительно, в этом образе все хорошо, кроме некоторых моментов...
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Полная финансовая зависимость от мужа. Отсутствие самореализации, неуверенность в себе,
                                                                        жизнь превратилась в жизнь "во имя семьи", "для мужа", "ради детей"...
                                                                    </p>
                                                                    <p>
                                                                        {{ clientData.name }}, о такой ли жизни вы мечтали?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesDom2"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Представьте, как было бы здорово, оставаясь в привычном ритме жизни,
                                                                        начать развиваться в интересном окружении, создать СВОЙ источник дохода и меньше
                                                                        зависеть от мужа, раскрыть свой личностный потенциал и наполнить жизнь новыми
                                                                        позитивными моментами. &#129395;
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesDom3"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        {{ clientData.name }}, какой собственный доход вы хотели бы иметь?
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-input">
                                                                <v-text-field
                                                                    :disabled="disInpMoney"
                                                                    label="Сколько хотите зарабатывать?"
                                                                    type="text"
                                                                    v-model.trim="clientData.income_want"
                                                                    :error-messages="moneyErrors"
                                                                    @input="$v.clientData.income_want.$touch()"
                                                                    @blur="$v.clientData.income_want.$touch()"
                                                                />
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnMoney"
                                                                    @click="btnMoney"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Хочу получать
                                                                    <span
                                                                        class="ml-2"
                                                                        style="text-decoration: underline"
                                                                    >
                                                                        {{ clientData.income_want }}
                                                                    </span>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answMoney"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime8 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Хочу получать {{ clientData.income_want }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz6"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime8 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Сейчас я вам расскажу, как выйти на такой доход, не
                                                                        бросая текущую деятельность, удаленно, в удобное для себя время.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz7"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime8 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p v-if="userData.gender === 'ж'">
                                                                        <!-- audio element -->
                                                                        <vue-player
                                                                            audio
                                                                            :sources="audioSources1g"
                                                                        />
                                                                    </p>
                                                                    <p v-else>
                                                                        <!-- audio element -->
                                                                        <vue-player
                                                                            audio
                                                                            :sources="audioSources1"
                                                                        />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeBiz1"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnBiz7"
                                                                        @click="btnBiz7"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answBiz7"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime9 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Далее
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz8"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime9 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p v-if="clientData.target_a === 'Работаю по найму'">
                                                                        Посмотрите результаты партнеров нашей команды,
                                                                        которые совмещают бизнес с LR или раньше работали по найму
                                                                    </p>
                                                                    <p v-if="clientData.target_a === 'Работаю на себя'">
                                                                        Посмотрите результаты партнеров нашей команды,
                                                                        которые работали на себя, а сейчас зарабатывают в LR
                                                                    </p>
                                                                    <p v-if="clientData.target_a === 'Свой бизнес'">
                                                                        Посмотрите результаты партнеров нашей команды,
                                                                        которые имеют свой бизнес и создали дополнительный источник дохода с LR
                                                                    </p>
                                                                    <p v-if="clientData.target_a === 'На пенсии'">
                                                                        Посмотрите результаты партнеров нашей команды,
                                                                        которые на пенсии создали дополнительный источник дохода с LR
                                                                    </p>
                                                                    <p v-if="clientData.target_a === 'Сетевик'">
                                                                        Посмотрите результаты партнеров нашей команды
                                                                    </p>
                                                                    <p v-if="clientData.target_a === 'В декрете'">
                                                                        Посмотрите результаты мамочек из нашей команды, которые так и не вышли из декрета,
                                                                        создав источник дохода с LR
                                                                    </p>
                                                                    <p v-if="clientData.target_a === 'Домохозяйка'">
                                                                        Посмотрите результаты домохозяек из нашей команды,
                                                                        которые создали свой собственный источник дохода с LR
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz9"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime9 }}
                                                            </div>
                                                            <div
                                                                v-if="clientData.target_a === 'Работаю по найму'"
                                                                class="chat-messages"
                                                            >
                                                                <p
                                                                    class="mb-5"
                                                                    style="width: 100%; max-width: 550px; height: auto;"
                                                                >
                                                                    <img style="width: 100%;" src="/img/leedbot/lid-chek-2.jpg" alt=""/>
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                Гэльсу, многодетная мама, инженер по образованию.
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                    До знакомства с LR работала по найму, главным градостроителем в крупном муниципальном образовании.
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                    В компании LR привлек качественный продукт, с помощью которого забыла о бронхиальной астме. 
                                                                    Через 4 месяца после начала сотрудничества с LR уже ездила на новом брендированном автомобиле от компании.
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                    Через 10 месяцев вышла на доход 100 000 рублей в месяц. Сейчас такие суммы получают уже десятки
                                                                    обученных партнеров в команде. Здесь яркая жизнь и высокие цели!
                                                                </p>

                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnOtzNaim1"
                                                                    @click="btnOtzNaim1"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Далее
                                                                </v-btn>

                                                                <transition name="fade">
                                                                    <div v-if="imgNaim2" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/biz-chek-8.jpg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Анна, до ЛР - медработник. Ночные работы в хирургии,  доход 8 тыс.руб., домашняя суета и нехватка денег.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        В 2019 году  поменяла найм на свой бизнес в LR. Уже в 2021 году получила автомобиль и в течение трёх лет вышла на доход 100.000 руб.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Сейчас финансово свободна, больше провожу времени с детьми и часто путешествую с семьёй.
                                                                        </p>

                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzNaim2"
                                                                            @click="btnOtzNaim2"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgNaim3" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/naim/naim3.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Анна, детский врач, многодетная мама.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            До LR работала по специальности, но уволилась по состоянию здоровья.
                                                                        </p>
                                                                        <p>
                                                                            В LR нашла решение многих проблем по здоровью, и решила начать бизнес.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Через полгода вышла на доход 72 тысячи в месяц, на 7-й месяц 90 тысяч,
                                                                            на 10-й мес получила VW поло, и еще через 10 мес поменяла его на Тигуан.
                                                                        </p>

                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzNaim3"
                                                                            @click="btnOtzNaim3"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgNaim4" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/naim/naim4.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Анна, до LR директор ювелирного магазина в найме.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В ЛР пришла на продукт, решила вопросы по здоровью у дочки с атоническим дерматитом и поняла,
                                                                            что хочу строить бизнес с этим продуктом и в этой компании.
                                                                            Да и банально хотелось больше денег и свободы.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Через 6 месяцев вышла на доход 50 000 руб, а через полтора года на 113 000 руб.
                                                                            Это первый год, когда мои дети не болели и я ощущаю свободу жизни.
                                                                        </p>

                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzNaim4"
                                                                            @click="btnOtzNaim4"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgNaim5" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/naim/naim5.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Артур, IT-шник.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Не хватало денег, искал варианты дополнительного дохода.
                                                                            Начал бизнес с LR совмещая с работой по найму.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Когда доход от LR превысил зарплату, уволился с работы.
                                                                            Появилось больше свободного времени. Могу с детьми больше времени проводить, куда-то взять
                                                                            и поехать посреди рабочего дня, ни у кого не отпрашиваясь.
                                                                        </p>

                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzNaim5"
                                                                            @click="btnOtzNaim5"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgNaim6" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/naim/naim6.png" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Елена.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        До LR была на службе в органах и сейчас на пенсии МВД, после ухода на пенсию мечтала найти своё дело,
                                                                        чтобы был свободный график, растущий доход, окружение интересных людей, путешествия!
                                                                        Сначала пришла в американскую сетевую компанию, была 2 года, ушла со складом в гараже более 300 тыс. руб и
                                                                        услышала о немецкой компании LR! 
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        В LR пришла на доход 100 000 руб, который увидела в брошюре компании, услышала, что в компании LR строят команды, получают автомобили и тд. 
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        В LR получила 2 автомобиля от компании, результат по здоровью, путешествия от компании, команду, крутое окружение, растущий доход и уже более 100 000 руб, о которых мечтала, уходя на пенсию! 
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Сегодня - Топ Лидер LR в России, с чеком более 230 тыс.руб
                                                                      </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzNaim6"
                                                                            @click="btnOtzNaim6"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                                <transition name="fade">
                                                                    <div v-if="imgNaim7" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/naim/naim7.png" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Марина и Владимир.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Мы всю жизнь занимались театральным делом. 
Пока вдруг не поняли, что даже любимая работа в театре не позволит нам обеспечить такую жизнь, какую мы хотим.
Тогда мы стали искать другие варианты. 
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        И нашли компанию, в которой сошлось все что мы хотели, начиная от надежности и немецкой педантичности к качеству своего продукта, до благородной миссии помогать людям улучшать качество жизни, решать проблемы здоровья и добиваться успеха.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        На сегодняшний, благодаря сотрудничеству с компанией LR, мы уже смогли осуществить некоторые свои мечты: стали ТОП- лидерами компании, имеем приличный доход и новый автомобиль от компании, летаем на лидерские обучения и в путешествия за счет компании.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        И это только начало...
                                                                      </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzNaim7"
                                                                            @click="btnOtzNaim7"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                            </div>
                                                            <div
                                                                v-if="clientData.target_a === 'Работаю на себя'"
                                                                class="chat-messages"
                                                            >
                                                                <p
                                                                    class="mb-5"
                                                                    style="width: 100%; max-width: 550px; height: auto;"
                                                                >
                                                                    <img style="width: 100%;" src="/img/leedbot/free/free1.jpeg" alt=""/>
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                    Оксана, был свой салон красоты, в кризис пришлось его закрыть и вернуться на работу по найму.
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                    В LR пришла, потому что хотела больше свободы.
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                    Через 3 года совмещая с работой, доход от LR составил 36 000 ₽, уволилась с основной работы и
                                                                    еще через пол года увеличила доход до 90 000 ₽, получила авто от компании.
                                                                </p>

                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnOtzFree1"
                                                                    @click="btnOtzFree1"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Далее
                                                                </v-btn>

                                                                <transition name="fade">
                                                                    <div v-if="imgFree2" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/free/free2.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Павел, 7 лет проработал в Евросети, затем пробовал себя в бизнесе,
                                                                            открывал свой магазин техники с итогом в минус 500 000₽.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В ЛР пришёл потому, что устал от графика 5/2 по 15-16 ч с дорогой и от постоянной нехватки денег.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Через пол года доход от LR составил 37 000₽ и получил первый авто.
                                                                            Закрыл долги на 6 млн ₽ и купил квартиру. Бизнес с ЛР дал мне свободу распоряжаться
                                                                            своим временем и не быть привязанным к месту.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzFree2"
                                                                            @click="btnOtzFree2"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgFree3" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/free/free3.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Михаил, предприниматель, занимался туризмом и пассажироперевозками.<br>
                                                                            Елена, строила карьеру в банке.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR пришли, т.к. устали "тушить пожары" в бизнесе и постоянно в него вкладывать,
                                                                            а Елена хотела совмещать бизнес с воспитанием дочерей.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            На 4-й месяц получили первый автомобиль, через пол года доход от LR был 50 000 руб,
                                                                            а ещё через год получили Мерседес от компании и доход увеличился в три раза.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Никогда ещё не чувствовали себя так свободно и уверенно в завтрашнем дне, как сейчас.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzFree3"
                                                                            @click="btnOtzFree3"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgFree4" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/free/free4.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Наталья, до LR работала парикмахером-модельером.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            К сетевому бизнесу был полный скепсис, но продукт меня влюбил.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Решила проблемы здоровья у детей - 9 лет не могли найти решения.
                                                                            По каким только медицинским центрам не ездили.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Через год вышла на доход в 72 000 рублей и получила Фольксваген поло, а еще через 9 месяцев
                                                                            получила Фольксваген Тигуан.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR я встретила много замечательных друзей, осознанных и целеустремлённых
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzFree4"
                                                                            @click="btnOtzFree4"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgFree5" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/free/free5.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Жанна, косметолог.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Прошла все «прелести» предпринимательства и работы по найму.
                                                                            Устала мечтать о деньгах, сидя на работе. Была в поиске лучшего коллектива, свободы и денег.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR нашла все, что искала! Через 3 месяца получила авто от компании.
                                                                            Сейчас уже четвертый авто от LR и ежемесячный доход 200 000 рублей.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzFree5"
                                                                            @click="btnOtzFree5"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                                <transition name="fade">
                                                                    <div v-if="imgFree6" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/free/free6.png" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Валентина. Ведущая, организатор праздников.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        10 лет держала праздничное агентство,  из за нехватки кадров много работала, начались серьёзные проблемы с здоровьем. Депрессии и осознание, что до старости прыгать на сцене с микрофоном не хочется.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        От компании LR убегала год, пока подруга не заставила принимать продукт. Через 3 месяца получила невероятные результаты по здоровью и включилась в продажи и рекомендации. Думала, что за это не платят, а когда попала на Фастрек, была поражена щедростью компании! 
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Оценила маркетинг, людей, которые здесь и приняла решение работать! Через год получила авто от компании, впервые в жизни села в самолёт, начала путешествовать и развиваться во многих сферах! В 2022 и в 2023 годах принимала участие в обучениях и отдыхе за счет компании, в октябре 2024 лечу в Грецию!
                                                                      </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzFree6"
                                                                            @click="btnOtzFree6"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                                <transition name="fade">
                                                                    <div v-if="imgFree7" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/free/free7.png" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Эмилия. Руководитель благотворительной организации помощи онкопациентам в Крыму.
                                                                      </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Родом с Донбаса. С2015 года с семьей живем в Крыму. В 2016 получила диагноз онкология, рак молочной железы.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        4,5 года до знакомства с ЛР страдала от побочек лечения. Пришла в компанию 1 января 2021 года. 
Возможность бизнеса рассмотрела на 4-й месяц и вышла на Фастрек. 
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Выполнила автопрограмму и уже больше года езжу на авто от ЛР за «0»₽.
                                                                      </p>
                                                                      <p
                                                                            class="mb-5"
                                                                        >
                                                                        Несу свою миссию помощи людям и через продукт компании, делая людей здоровее и счастливее!
                                                                    </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzFree7"
                                                                            @click="btnOtzFree7"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                                <transition name="fade">
                                                                    <div v-if="imgFree8" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/free/free8.png" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Татьяна. 
Визажист, мастер по наращиванию ресниц. Уровень дохода до ЛР 50 - 70 000 ₽.
                                                                      </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        В ЛР пришла по рекомендации друзей, за компанию ничего не попробовав, на полном доверии. 
Очень нужно было улучшить состояние кожи. 

                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Мечтала об автомобиле, и знала, что в ЛР есть автопрограмма, но не понимала как она работает.
В ЛР осуществила все, о чем мечтала на старте и то, о чем даже боялась мечтать.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        В ближайшие месяцы я вместе с нашей сибирской командой стану Серебряным Лидером организации.
                                                                    </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzFree8"
                                                                            @click="btnOtzFree8"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                            </div>
                                                            <div
                                                                v-if="clientData.target_a === 'Свой бизнес'"
                                                                class="chat-messages"
                                                            >
                                                                <p
                                                                    class="mb-5"
                                                                    style="width: 100%; max-width: 550px; height: auto;"
                                                                >
                                                                    <img style="width: 100%;" src="/img/leedbot/biz-chek-9.png" alt=""/>
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                    Дамир, инженер-строитель. По образованию не работал. После окончания университета ушел в продажи.
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                До LR был предпринимателем. В LR пригласила супруга. 
                    Через 1,4 года сотрудничества с компанией, получили автомобиль VW Polo, результат по здоровью у детей.
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                    Еще через 1,5 года получили второй автомобиль VW Tiguan, и еще через 3 года получили третий автомобиль VW Tiguan.
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                    За 6 лет сотрудничества с LR вышли на доход 383 тыс. руб. 
Сегодня - Топ Лидер в России, с годовым бонусом (13-я зарплата) 650 тыс. руб.
                                                                </p>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnOtzEnt1"
                                                                    @click="btnOtzEnt1"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Далее
                                                                </v-btn>

                                                                <transition name="fade">
                                                                    <div v-if="imgEnt2" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/ent/ent2.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Сергей, предприниматель, была клининговая компания.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Рассмотрел LR как еще одно направление бизнеса и сделал его основным для себя,
                                                                            найдя в нём абсолютную свободу и возможность постоянного развития.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Первый авто получил через 4 месяца. Через год доход в LR был более 70 тыс. руб. в месяц.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzEnt2"
                                                                            @click="btnOtzEnt2"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgEnt3" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/ent/ent3.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Татьяна, был бизнес в сфере межкомнатные двери.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR пришла потому, что устала «жить на работе».
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Через три месяца первый авто, через полгода доход от LR 55.000, через год второй авто Мерседес.
                                                                            Закрыла все кредиты. Купила первую свою квартиру.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzEnt3"
                                                                            @click="btnOtzEnt3"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgEnt4" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/ent/ent4.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Роман, занимаюсь недвижимостью.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В ЛР пришёл построить дополнительный источник дохода.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Через 9 месяцев получил автомобиль от LR.
                                                                            Уже несколько лет получаю стабильно от ЛР около 100 тысяч в месяц.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzEnt4"
                                                                            @click="btnOtzEnt4"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgEnt5" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/ent/biz-chek-1.jpg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Александра, кандидат химических наук, начинала бизнес в декрете.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Общий доход от LR с супругом более 2 миллионов рублей в месяц.
                                                                            Две машины в семье от LR.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Благодаря LR моя жизнь поменялась как будто в кино, потому что 3 года назад я не могла
                                                                            себе представить такую жизнь даже в самых смелых мечтах. Купили большой дом,
                                                                            много путешествуем, помогаем родителям.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzEnt5"
                                                                            @click="btnOtzEnt5"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                                <transition name="fade">
                                                                    <div v-if="imgEnt6" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/ent/ent6.png" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Антон и Татьяна, предприниматели, до LR опыт традиционного бизнеса 30 лет.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        В Компанию LR пришли вместе в 2020 году - увидели все преимущества сетевого Маркетинга.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        А когда получил свой результат по здоровью, не смогли молчать и сейчас всем рекомендуем наши продукты для Здоровья и маркетинг план для финансовой свободы! 
Сейчас мы в статусе Лидеры Организации LR
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzEnt6"
                                                                            @click="btnOtzEnt6"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                            </div>
                                                            <div
                                                                v-if="clientData.target_a === 'На пенсии'"
                                                                class="chat-messages"
                                                            >
                                                                <p
                                                                    class="mb-5"
                                                                    style="width: 100%; max-width: 550px; height: auto;"
                                                                >
                                                                    <img style="width: 100%;" src="/img/leedbot/naim/naim6.png" alt=""/>
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                Елена
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >                                                                    
До LR была на службе в гос.органах. После ухода на пенсию мечтала найти своё дело, чтобы был свободный график, растущий доход, окружение интересных людей, путешествия! 
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                Сначала пришла в американскую сетевую компанию. После 2 лет работы ушла со складом в гараже более 300 тыс. руб. и тогда же услышала о немецкой компании LR,
куда на доход 100 000 руб, который увидела в брошюре компании, услышала, что в компании LR строят команды, получают автомобили и тд. 
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                В LR получила 2 автомобиля от компании, результат по здоровью, путешествия, команду, крутое окружение, растущий доход и уже более 100 000 руб.в мес, 
о которых мечтала уходя на пенсию!
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                Сегодня - Топ Лидер LR в России, с чеком более 230 тыс.руб
                                                                </p>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnOtzPfr1"
                                                                    @click="btnOtzPfr1"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Далее
                                                                </v-btn>

                                                                <!-- <transition name="fade">
                                                                    <div v-if="imgPfr2" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/naim/naim6.png" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Елена, в прошлом госслужба, руководитель отдела кадров.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Пришла в LR за свободой (без начальника и свободный график), за растущими доходом!
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Получила второй автомобиль от компании VW Tiguan и ежемесячный доход более 180 тысяч рублей.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzPfr2"
                                                                            @click="btnOtzPfr2"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition> -->

                                                                <transition name="fade">
                                                                    <div v-if="imgPfr3" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/pfr/pfr3.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Мария, 22 года, студентка.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Бизнес с LR получила по наследству от отца. Продолжаю его дело.
                                                                            Мне не пришлось искать работу, ежемесячного дохода в 145 тысяч от LR хватает,
                                                                            чтобы жить и путешествовать. Живу на Бали. В LR нашла много интересных друзей.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzPfr3"
                                                                            @click="btnOtzPfr3"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgPfr4" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/pfr/pfr4.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Любовь, в прошлом педагог.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Пришла в LR из другой сетевой компании, пришла на автомобиль.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Свою первую машину от LR получила уже через пол года.
                                                                            Сейчас у меня уже две машины от Компании и стабильный доход более 100 000 руб.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzPfr4"
                                                                            @click="btnOtzPfr4"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgPfr5" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/pfr/pfr5.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Тагир, в прошлом ген директор строительной компании, Октябрина в прошлом зам главы района.
                                                                            Сейчас пенсионеры.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Как попали в LR. Как говориться «не было бы счастья, да несчастье помогло».
                                                                            Октябрина попала в сильную автомобильную аварию.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Восстанавливая здоровье познакомились с продуктом LR и получили потрясающие,
                                                                            причем быстрые результаты.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzPfr5"
                                                                            @click="btnOtzPfr5"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                            </div>
                                                            <div
                                                                v-if="clientData.target_a === 'Сетевик'"
                                                                class="chat-messages"
                                                            >
                                                                <p
                                                                    class="mb-5"
                                                                    style="width: 100%; max-width: 550px; height: auto;"
                                                                >
                                                                    <img style="width: 100%;" src="/img/leedbot/biz-chek-2.png" alt=""/>
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                Людмила
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                До LR работала в американской сетевой компании 12 лет. Ушла с долгом более 200 тыс.руб и решила что “Сетевой, это не мое!»
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                В LR пришла с мужем на «Немецкое качество» 
Через 2,5 месяца получили свой личный автомобиль, результат по здоровью, через 3 года закрыли ипотеку и все долги по кредиткам.
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                Сегодня - Топ Лидер 4 в России, с чеком более 700 тыс.руб
                                                                </p>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnOtzMlm1"
                                                                    @click="btnOtzMlm1"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Далее
                                                                </v-btn>

                                                                <transition name="fade">
                                                                    <div v-if="imgMlm2" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/mlm/mlm2.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Вера, до LR была в другой компании 10 лет.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Но дохода и команды не было. Люди приходили, поправляли здоровье и уходили.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR через 8 месяцев вышла на доход 55.000 ₽. И получила новенький VW Polo от LR.
                                                                            Через год поменяла на Тигуан. Благодаря бизнесу купила квартиру и полностью ее обставила.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzMlm2"
                                                                            @click="btnOtzMlm2"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgMlm3" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/mlm/biz-chek-28.png" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Юлия, всю жизнь в сетевом. По профессии менеджер по туризму.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR пришла, потому что увидела более интересные условия за одну и ту же работу -
                                                                            приятно получать больше денег и машин.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Через 6 месяцев получила свой первый автомобиль, доход был более 40000р.
                                                                            Ещё через 6 месяцев полетела с мужем бесплатно на завод в Германию.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzMlm3"
                                                                            @click="btnOtzMlm3"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgMlm4" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/mlm/mlm4.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Гульнара, работала в обувном магазине.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR заинтересовала автопрограмма и заработок. Через полгода вышла на доход 19 000 рублей,
                                                                            а через год на 69 000. Закрыли все долги и кредиты, переехали в своё жильё,
                                                                            получили 4 авто от LR. Сейчас доход более 600 тысяч ежемесячно.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzMlm4"
                                                                            @click="btnOtzMlm4"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgMlm5" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/mlm/mlm5.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Андрей, работал в университете, открывал свой бизнес в ивент индустрии.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В ЛР пришел за свободой и возможностью путешествовать. Через пол года 1000 долларов,
                                                                            через год 2500. Автомобиль получил через пол года после старта.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Благодаря ЛР посетил 40 стран, и 5 лет зимовал в тёплых странах.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzMlm5"
                                                                            @click="btnOtzMlm5"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                                <transition name="fade">
                                                                    <div v-if="imgMlm6" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/mlm/mlm6.png" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Ольга, до ЛР я работала в Мэри Кэй 5 лет, а до этого работала начальником отдела кадров в ЖКХ.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Благодаря компании ЛР я нашла в себе не только предпринимательскую жилку, но и заботу о своем здоровье и красоте. Увидев потрясающие результаты продукции как на себе, так и на своих клиентах, я почувствовала внутреннее сияние и уверенность, которые невозможно забыть. 
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Теперь, моя амбициозная цель - не только достичь бизнес-успеха, но и наслаждаться каждым моментом жизни, путешествовать по миру, и находить время для семьи и близких. 
Я глубоко уверена, благодаря ЛР мои самые заветные мечты станут реальностью, окутанные блеском и красотой!
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzMlm6"
                                                                            @click="btnOtzMlm6"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                            </div>
                                                            <div
                                                                v-if="clientData.target_a === 'В декрете'"
                                                                class="chat-messages"
                                                            >
                                                                <p
                                                                    class="mb-5"
                                                                    style="width: 100%; max-width: 550px; height: auto;"
                                                                >
                                                                    <img style="width: 100%;" src="/img/leedbot/lid-chek-6.jpg" alt=""/>
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                Александра, мама 2-х дочек, по образованию химик.
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                До LR работала инженером в институте с з/п 15 тыс руб. 
Познакомилась с компанией будучи в первом декрете: привлекла возможность иметь доход в 100 тыс руб и свободный стиль жизни. 
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                Через 1,5 года вышла на доход около 100 тыс руб. 
Сейчас, спустя 11 лет, доход в LR около 2-х млн руб в месяц, годовой бонус 8 млн руб, авто от компании мерседес GLE в собственности.
В команде выдано уже более 100 авто и сотни людей зарабатывают хорошие деньги.
                                                                </p>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnOtzDekret1"
                                                                    @click="btnOtzDekret1"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Далее
                                                                </v-btn>

                                                                <transition name="fade">
                                                                    <div v-if="imgDekret2" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/dekret/dekret2.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Татьяна, мама в декрете.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            С компанией ЛР познакомилась когда была в первом декрете. Часто болела дочка.
                                                                            Пользовались продуктом с удовольствием и получили отличные результаты.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Когда пошла во второй декрет, решила строить свой бизнес. Через полгода доход 18.000 руб,
                                                                            а еще через полгода 90.000 рублей и автомобиль.
                                                                            Сейчас ЛР это часть моей жизни, отличный инструмент помогать людям быть здоровыми и счастливыми.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzDekret2"
                                                                            @click="btnOtzDekret2"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgDekret3" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/dekret/dekret3.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Ляйсан, работала в гос институте.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Через 10 месяцев уволилась с работы, потому что доход превысил зарплату.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Теперь у меня есть свободное время, которого мне, как многодетной маме,
                                                                            так не хватало и комфортный немецкий автомобиль.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzDekret3"
                                                                            @click="btnOtzDekret3"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgDekret5" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/dekret/dekret5.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Ольга, в прошлом менеджер по продажам.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR пришла будучи в декрете, заинтересовал Немецкий продукт.
                                                                            Получила массу результатов и решила развивать бизнес.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                             LR – это настоящий подарок судьбы для нашей семьи.
                                                                            Через пол года вышла на автобонус, а через год доход от LR составлял 90 000 рублей в мес.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzDekret5"
                                                                            @click="btnOtzDekret5"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                            </div>
                                                            <div
                                                                v-if="clientData.target_a === 'Домохозяйка'"
                                                                class="chat-messages"
                                                            >
                                                                <p
                                                                   class="mb-5"
                                                                    style="width: 100%; max-width: 550px; height: auto;"
                                                                >
                                                                    <img style="width: 100%;" src="/img/leedbot/lid-chek-3.jpg" alt=""/>
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                Людмила, химик по образованию, 20 лет являюсь домохозяйкой.
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                В компанию LR пришла на замечательный продукт в 2019 году. 
Разобралась с продуктом и ровно через год осознанно включилась в бизнес помогать людям зарабатывать деньги и оздоравливаться. 
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                На третий месяц вышла на зарплату 72 000 руб. 
В команде уже 4 партнёра имеют  автомобили  Volkswagen Polo, 2 партнёра в ожидании. 
Я тоже в ожидании автомобиля маркой - Volkswagen Tiguan. 
                                                                </p>
                                                                <p
                                                                    class="mb-5"
                                                                >
                                                                Партнёры в моей команде зарабатывают и меняют качество своей жизни. 
Два раза номинировалась на поездку в Германию от компании.
                                                                </p>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnOtzDom1"
                                                                    @click="btnOtzDom1"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Далее
                                                                </v-btn>

                                                                <transition name="fade">
                                                                    <div v-if="imgDom2" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/dom/dom2.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Лейла, домохозяйка, юрист по образованию.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR пришла потому что устала от нехватки денег и экономии во всём.
                                                                            Очень хотелось финансовой стабильности и больше времени для себя и семьи.
                                                                            У нас никогда не было иномарки, мы очень хотели. Благодаря LR у нас VW Tiguan.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzDom2"
                                                                            @click="btnOtzDom2"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgDom3" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/dom/dom3.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Лусине, домохозяйка.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR я увидела возможность вести бизнес, и при этом находиться весь день с родными.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            На седьмой месяц получила свой первый автомобиль, а 3 месяца назад получила от компании
                                                                            вторую машину.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Я занимаюсь любимым делом, и это и меня воодушевляет и вдохновляет!
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzDom3"
                                                                            @click="btnOtzDom3"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgDom4" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/dom/dom4.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Зухра, домохозяйка многодетная мама.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Пришла на продукт, болела Астмой 15 лет. Долгое время была просто клиентом.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Сейчас мой доход более 200 тысяч рублей и совсем скоро получим второй автомобиль VW Tiguan.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzDom4"
                                                                            @click="btnOtzDom4"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgDom5" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/dom/dom5.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Светлана, 50 лет, в прошлом медицинский работник.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Пришла в компанию, чтобы поправить здоровье, алоэ очень эффективно выводит рентген
                                                                            и тяжелые металлы. Увидела результат сразу.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Через пол года активной работы получила автомобиль и вышла на доход 36 000 рублей.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzDom5"
                                                                            @click="btnOtzDom5"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                                <transition name="fade">
                                                                    <div v-if="imgDom6" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/dom/dom6.jpg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Людмила, химик по образованию. 22 года являюсь домохозяйкой. 
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        В компанию LR пришла на замечательный продукт в 19-м году, разобралась с продуктом  и ровно через год осознанно включилась в бизнес помогать людям зарабатывать деньги и оздоравливаться. 
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        В команде 4 партнёра уже имеют  автомобили  Volkswagen Polo, 2 партнёра в ожидании. 
В этом году получила новенький HAVAL в пользование от компании за 0 рублей. 
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Партнёры в моей команде зарабатывают и меняют качество жизни. 
На третий месяц вышла на зарплату 72000 руб. 
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                        Два раза номинировалась на поездку в Германию от компании.
Была в Марокко от компании.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzDom6"
                                                                            @click="btnOtzDom6"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz10_1"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime10 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Эта информация может вообще никак не повлиять на вашу жизнь
                                                                        и все останется так, как есть сейчас.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Либо вы можете не только увеличить доход и решить какие-то текущие проблемы,
                                                                        но и сможете полностью ИЗМЕНИТЬ КАЧЕСТВО ВАШЕЙ ЖИЗНИ.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Все зависит только от вашего решения.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Пример того, как решение заняться этим бизнесом
                                                                        может полностью изменить жизнь, посмотрите в видео ниже:
                                                                    </p>
                                                                    <div class="thumb-wrap mb-10">
                                                                        <iframe
                                                                            width="560"
                                                                            height="315"
                                                                            src="https://www.youtube.com/embed/HQJ9osNcngM"
                                                                            title="Как LR меняет жизнь"
                                                                            frameborder="0"
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowfullscreen
                                                                        ></iframe>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeBiz2_1"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnBiz9_1"
                                                                        @click="btnBiz9_1"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answBiz9_1"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime10 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Далее
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz10"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime10 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Сейчас расскажу коротко о компании LR и почему мы выбрали именно эту компанию
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz11"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime10 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p v-if="userData.gender === 'ж'">
                                                                        <!-- audio element -->
                                                                        <vue-player
                                                                            audio
                                                                            :sources="audioSources2g"
                                                                        />
                                                                    </p>
                                                                    <p v-else>
                                                                        <!-- audio element -->
                                                                        <vue-player
                                                                            audio
                                                                            :sources="audioSources2"
                                                                        />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeBiz3"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnBiz11"
                                                                        @click="btnBiz11"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Посмотреть видео
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

<!--                                                <transition name="fade">-->
<!--                                                    <div-->
<!--                                                        class="chat-item item-user"-->
<!--                                                        v-if="answBiz11"-->
<!--                                                    >-->
<!--                                                        <div class="chat-avatar-user"></div>-->
<!--                                                        <div class="chat-block">-->
<!--                                                            <div class="chat-date">-->
<!--                                                                {{ nowDateTime.dateTime11 }}-->
<!--                                                            </div>-->
<!--                                                            <div class="chat-messages">-->
<!--                                                                <div class="chat-message">-->
<!--                                                                    Далее-->
<!--                                                                </div>-->
<!--                                                            </div>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </transition>-->

<!--                                                <transition name="fade">-->
<!--                                                    <div-->
<!--                                                        class="chat-item"-->
<!--                                                        v-if="mesBiz12"-->
<!--                                                    >-->
<!--                                                        <div class="chat-avatar">-->
<!--                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"-->
<!--                                                                 alt=""-->
<!--                                                            >-->
<!--                                                        </div>-->
<!--                                                        <div class="chat-block">-->
<!--                                                            <div class="chat-date">-->
<!--                                                                {{ nowDateTime.dateTime11 }}-->
<!--                                                            </div>-->
<!--                                                            <div class="chat-messages">-->
<!--                                                                <div class="chat-message">-->
<!--                                                                    <p>-->
<!--                                                                        Посмотрите минутное видео об основных продуктовых линейках компании-->
<!--                                                                    </p>-->
<!--                                                                </div>-->
<!--                                                            </div>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </transition>-->

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz13"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime11 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message" style="width: 100%">
                                                                    <div class="thumb-wrap">
                                                                        <iframe
                                                                            width="560"
                                                                            height="315"
                                                                            src="https://www.youtube.com/embed/VzjFiA29GcI"
                                                                            title="О компании LR"
                                                                            frameborder="0"
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowfullscreen
                                                                        ></iframe>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeBiz4"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnBiz13"
                                                                        @click="btnBiz13"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answBiz13"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime12 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Далее
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz14"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime12 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Сейчас расскажу, что нужно делать, чтобы здесь зарабатывать
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz15"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime12 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message" style="width: 100%">
                                                                    <div class="thumb-wrap-how">
                                                                      <iframe width="560" height="315" src="https://www.youtube.com/embed/GZLFWnFZJCw?si=LLW2W6FrM2fngYH2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeBiz5"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnBiz15"
                                                                        @click="btnBiz15"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answBiz15"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime13 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Далее
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz16"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime13 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message" style="width: 100%;">
                                                                    <p class="mb-5">
                                                                        Посмотрите отзывы людей, которые начали этот бизнес:
                                                                    </p>
                                                                    <div class="thumb-wrap">
                                                                      <iframe width="560" height="315" src="https://www.youtube.com/embed/U840Q-TIxLk?si=mx2zLlVeTEdqsD5v" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeBiz6_1"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnBiz17_1"
                                                                        @click="btnBiz17_1"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz17"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime13 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <p
                                                                    class="mb-5"
                                                                    style="width: 100%; max-width: 550px; height: auto;"
                                                                >
                                                                    <img style="width: 100%;" src="/img/leedbot/avto/avto1.jpeg" alt=""/>
                                                                </p>
                                                                <p class="mb-5">
                                                                    Роман, до прихода в LR работал менеджером.
                                                                </p>
                                                                <p class="mb-5">
                                                                    Не устраивало: потолок по зарплате, работа от звонка до звонка, никакой свободы и мало перспектив.
                                                                </p>
                                                                <p class="mb-5">
                                                                    В LR получил всё, о чем мечтал. Через 2,5 года "уволил" работодателя, обретя свободу,
                                                                    увеличил доход в 3 раза (до 130 000 руб), получил уже 2 авто от Компании (VW Polo и Tiguan)
                                                                    и нашёл много единомышленников с кем активно развиваем сейчас общий бизнес.
                                                                </p>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnOtzAvto1"
                                                                    @click="btnOtzAvto1"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Далее
                                                                </v-btn>

                                                                <transition name="fade">
                                                                    <div v-if="imgAvto2" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/avto/avto2.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Ольга, 17 лет работала бухгалтером.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Уволилась с работы, потому что не получалось уделять больше времени троим детям,
                                                                            полноценно заниматься их воспитанием и учебой.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Через год получила авто от LR, а через 1,5 года вышла на ежемесячный доход 90 000 руб.
                                                                            Досрочно закрыла кредит.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzAvto2"
                                                                            @click="btnOtzAvto2"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgAvto3" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/avto/avto3.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Ильгина, в прошлом инженер в нефтяной компании.
                                                                        </p>
                                                                        <p>
                                                                            Работа в жестком графике, было мало времени на себя и семью.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В ЛР пришла за свободой распоряжаться своим временем, личностным и финансовым ростом.
                                                                            Через пол года в нашей семье появился первый авто от LR.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzAvto3"
                                                                            @click="btnOtzAvto3"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgAvto4" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/avto/avto4.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Татьяна, до LR медработник - оптометрист с з/п на крайнем севере 20000.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR пришла потому что устала от кредитов и постоянной нехватки денег даже на минимум.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Сейчас мой ежемесячный доход 90 000, уволилась с работы и получила авто от компании.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzAvto4"
                                                                            @click="btnOtzAvto4"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div v-if="imgAvto5" class="mt-10">
                                                                        <p
                                                                            class="mb-5"
                                                                            style="width: 100%; max-width: 550px; height: auto;"
                                                                        >
                                                                            <img style="width: 100%;" src="/img/leedbot/avto/avto5.jpeg" alt=""/>
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            Надежда, в прошлом физик-математик.
                                                                        </p>
                                                                        <p
                                                                            class="mb-5"
                                                                        >
                                                                            В LR пришла во время декретного отпуска, когда уже собиралась открыть свой бизнес - мыловарню.
                                                                            Через 1,5 года получила свой первый авто от компании. Сейчас уже второй авто.
                                                                            Доходы от LR позволили не выходить после декрета на работу и не заморачиваться с мыловарней.
                                                                        </p>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnOtzAvto5"
                                                                            @click="btnOtzAvto5"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz18"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime14 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        {{ clientData.name }}, почему вы сможете начать зарабатывать с нами:
                                                                    </p>
                                                                    <ul>
                                                                        <li>
                                                                            <v-icon color="green" class="mr-2">mdi-check-bold</v-icon>
                                                                            Пройдете пошаговое обучение
                                                                        </li>
                                                                        <li>
                                                                            <v-icon color="green" class="mr-2">mdi-check-bold</v-icon>
                                                                            Получите современные инструменты
                                                                        </li>
                                                                        <li>
                                                                            <v-icon color="green" class="mr-2">mdi-check-bold</v-icon>
                                                                            У вас будет личный куратор, который проведет по всем шагам и поможет быстрее начать
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz19"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime14 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Хотите узнать, как начать, или остались вопросы?
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Оставьте заявку и я помогу разобраться.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Выберите удобный для вас мессенджер, куда я также скину обещанный подарок:
                                                                    </p>
                                                                    <div v-if="userData.leedbonus">
                                                                        <span v-html="userData.leedbonus"></span>
                                                                    </div>
                                                                    <div v-else>
                                                                        <p style="width: 100%; max-width: 450px; height: auto">
                                                                            <img style="width: 100%;" src="/img/leedbot/leed-book.jpg" alt=""/>
                                                                        </p>
                                                                        <p>
                                                                            7 книг в коротком изложении, которые помогут запустить мощные перемены в жизни.
                                                                            Выжимка ключевых идей книги в аудио и текстовом формате,
                                                                            которую можно изучить всего за 30 минут.
                                                                        </p>
                                                                    </div>
                                                                    <div class="mt-7">
                                                                        <v-btn
                                                                            fab
                                                                            dark
                                                                            small
                                                                            class="mr-3"
                                                                            color="success"
                                                                            @click="btnWhatsapp"
                                                                        >
                                                                            <v-icon>mdi-whatsapp</v-icon>
                                                                        </v-btn>
<!--                                                                        <v-btn-->
<!--                                                                            fab-->
<!--                                                                            dark-->
<!--                                                                            small-->
<!--                                                                            class="mr-3"-->
<!--                                                                            color="blue"-->
<!--                                                                            @click="btnTelegram"-->
<!--                                                                        >-->
<!--                                                                            <v-icon>mdi-send</v-icon>-->
<!--                                                                        </v-btn>-->
                                                                        <v-btn
                                                                            fab
                                                                            dark
                                                                            small
                                                                            class="mr-3"
                                                                            color="deep-purple lighten-2"
                                                                            @click="btnViber"
                                                                        >
                                                                            <v-icon>fab fa-viber</v-icon>
                                                                        </v-btn>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="btnWhatsappTrue">
                                                                <div class="chat-input">
                                                                    <v-text-field
                                                                        :disabled="disInpWhatsapp"
                                                                        label="Номер Whatsapp"
                                                                        prepend-icon="mdi-whatsapp"
                                                                        type="text"
                                                                        v-model.trim="clientData.phone_whatsapp"
                                                                        :error-messages="phoneWhatsappErrors"
                                                                        @input="$v.clientData.phone_whatsapp.$touch()"
                                                                        @blur="$v.clientData.phone_whatsapp.$touch()"
                                                                        hint="79123456789"
                                                                        persistent-hint
                                                                    />
                                                                </div>
                                                                <div class="chat-controls">
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnBiz19"
                                                                        @click="btnSendBiz"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Отправить
                                                                    </v-btn>
                                                                </div>
                                                                <p class="privacy-text">
                                                                    Оставляя заявку, вы даете свое
                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                                                                    и принимаете
                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.
                                                                </p>
                                                            </div>
<!--                                                            <div v-if="btnTelegramTrue">-->
<!--                                                                <div class="chat-input">-->
<!--                                                                    <v-text-field-->
<!--                                                                        :disabled="disInpTelegram"-->
<!--                                                                        label="Имя пользователя в telegram"-->
<!--                                                                        prepend-icon="mdi-send"-->
<!--                                                                        type="text"-->
<!--                                                                        v-model.trim="clientData.telegram"-->
<!--                                                                        :error-messages="telegramErrors"-->
<!--                                                                        @input="$v.clientData.telegram.$touch()"-->
<!--                                                                        @blur="$v.clientData.telegram.$touch()"-->
<!--                                                                        hint="username"-->
<!--                                                                        persistent-hint-->
<!--                                                                    />-->
<!--                                                                </div>-->
<!--                                                                <div class="chat-controls">-->
<!--                                                                    <v-btn-->
<!--                                                                        class="chat-btn"-->
<!--                                                                        :disabled="disBtnBiz19"-->
<!--                                                                        @click="btnSendBiz"-->
<!--                                                                    >-->
<!--                                                                        <v-icon class="mr-3">mdi-send</v-icon>-->
<!--                                                                        Отправить-->
<!--                                                                    </v-btn>-->
<!--                                                                </div>-->
<!--                                                                <p class="privacy-text">-->
<!--                                                                    Оставляя заявку, вы даете свое-->
<!--                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>-->
<!--                                                                    и принимаете-->
<!--                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.-->
<!--                                                                </p>-->
<!--                                                            </div>-->
                                                            <div v-if="btnViberTrue">
                                                                <div class="chat-input">
                                                                    <v-text-field
                                                                        :disabled="disInpViber"
                                                                        label="Номер Viber"
                                                                        prepend-icon="fab fa-viber"
                                                                        type="text"
                                                                        v-model.trim="clientData.phone_viber"
                                                                        :error-messages="phoneViberErrors"
                                                                        @input="$v.clientData.phone_viber.$touch()"
                                                                        @blur="$v.clientData.phone_viber.$touch()"
                                                                        hint="79123456789"
                                                                        persistent-hint
                                                                    />
                                                                </div>
                                                                <div class="chat-controls">
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnBiz19"
                                                                        @click="btnSendBiz"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Отправить
                                                                    </v-btn>
                                                                </div>
                                                                <p class="privacy-text">
                                                                    Оставляя заявку, вы даете свое
                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                                                                    и принимаете
                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz20"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime15 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">Заявка отправлена, я свяжусь с вами в ближайшее время</p>
                                                                    <p class="mb-3 mr-3">
                                                                        Или напишите мне:
                                                                    </p>
                                                                    <p>
                                                                        <a
                                                                            v-if="userData.phone_whatsapp"
                                                                            style="text-decoration: none"
                                                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="success"
                                                                            >
                                                                                <v-icon>mdi-whatsapp</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.phone_viber"
                                                                            style="text-decoration: none"
                                                                            :href="`viber://chat?number=${userData.phone_viber}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="deep-purple lighten-2"
                                                                            >
                                                                                <v-icon>fab fa-viber</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.telegram"
                                                                            style="text-decoration: none"
                                                                            :href="`${userData.telegram}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="blue"
                                                                            >
                                                                                <v-icon>mdi-send</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.fb_messenger"
                                                                            :href="`${userData.fb_messenger}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="light-blue"
                                                                            >
                                                                                <v-icon>mdi-facebook-messenger</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.vkontakte"
                                                                            :href="`${userData.vkontakte}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="blue darken-2"
                                                                            >
                                                                                <v-icon>fab fa-vk</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.odnoklassniki"
                                                                            :href="`${userData.odnoklassniki}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="orange darken-2"
                                                                            >
                                                                                <v-icon>mdi-odnoklassniki</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.instagram"
                                                                            :href="`${userData.instagram}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="pink darken-1"
                                                                            >
                                                                                <v-icon>mdi-instagram</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz21"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime15 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Пока можете изучить информацию о продукции компании
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls-end">
                                                                <v-btn
                                                                    class="chat-btn btn-skip"
                                                                    :disabled="disBtnBizSkip21"
                                                                    @click="btnBizSkip21"
                                                                >
                                                                    Пропустить
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnBiz21"
                                                                    @click="btnBiz21"
                                                                >
                                                                    Расскажи про продукт
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answBiz21"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime16 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    {{ answerBiz25 }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesBiz22"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime16 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Хорошо, больше не отвлекаю. Свяжусь с вами в ближайшее время.
                                                                    </p>
                                                                    <p class="mb-3 mr-3">
                                                                        Или напишите мне:
                                                                    </p>
                                                                    <p>
                                                                        <a
                                                                            v-if="userData.phone_whatsapp"
                                                                            style="text-decoration: none"
                                                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="success"
                                                                            >
                                                                                <v-icon>mdi-whatsapp</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.phone_viber"
                                                                            style="text-decoration: none"
                                                                            :href="`viber://chat?number=${userData.phone_viber}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="deep-purple lighten-2"
                                                                            >
                                                                                <v-icon>fab fa-viber</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.telegram"
                                                                            style="text-decoration: none"
                                                                            :href="`${userData.telegram}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="blue"
                                                                            >
                                                                                <v-icon>mdi-send</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.fb_messenger"
                                                                            :href="`${userData.fb_messenger}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="light-blue"
                                                                            >
                                                                                <v-icon>mdi-facebook-messenger</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.vkontakte"
                                                                            :href="`${userData.vkontakte}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="blue darken-2"
                                                                            >
                                                                                <v-icon>fab fa-vk</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.odnoklassniki"
                                                                            :href="`${userData.odnoklassniki}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="orange darken-2"
                                                                            >
                                                                                <v-icon>mdi-odnoklassniki</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.instagram"
                                                                            :href="`${userData.instagram}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="pink darken-1"
                                                                            >
                                                                                <v-icon>mdi-instagram</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd1"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime2 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Мы сотрудничаем с немецкой компанией-производителем продукции,
                                                                        с помощью которой можно:
                                                                    </p>
                                                                    <ul>
                                                                        <li>
                                                                            <v-icon color="green" class="mr-2">mdi-check-bold</v-icon>
                                                                            Укрепить иммунитет
                                                                        </li>
                                                                        <li>
                                                                            <v-icon color="green" class="mr-2">mdi-check-bold</v-icon>
                                                                            Убрать живот
                                                                        </li>
                                                                        <li>
                                                                            <v-icon color="green" class="mr-2">mdi-check-bold</v-icon>
                                                                            Снизить утомляемость в течение дня
                                                                        </li>
                                                                        <li>
                                                                            <v-icon color="green" class="mr-2">mdi-check-bold</v-icon>
                                                                            Уменьшить стресс
                                                                        </li>
                                                                        <li>
                                                                            <v-icon color="green" class="mr-2">mdi-check-bold</v-icon>
                                                                            Укрепить суставы
                                                                        </li>
                                                                        <li>
                                                                            <v-icon color="green" class="mr-2">mdi-check-bold</v-icon>
                                                                            Выглядеть моложе
                                                                        </li>
                                                                        <li>
                                                                            <v-icon color="green" class="mr-2">mdi-check-bold</v-icon>
                                                                            Меньше болеть
                                                                        </li>
                                                                        <li>
                                                                            <v-icon color="green" class="mr-2">mdi-check-bold</v-icon>
                                                                            Избавиться от аллергии и проблем с кожей
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd2"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime2 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Коротко о компании LR Health & Beauty
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd3"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime2 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p v-if="userData.gender === 'ж'">
                                                                        <!-- audio element -->
                                                                        <vue-player
                                                                            audio
                                                                            :sources="audioSources3g"
                                                                        />
                                                                    </p>
                                                                    <p v-else>
                                                                        <!-- audio element -->
                                                                        <vue-player
                                                                            audio
                                                                            :sources="audioSources3"
                                                                        />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeProd1"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnProd3"
                                                                        @click="btnProd3"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answProd3"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime3 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Далее
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd4"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime3 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Посмотрите видео о продукции компании LR
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd5"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime3 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message" style="width: 100%;">
                                                                    <div class="thumb-wrap">
                                                                        <iframe
                                                                            width="560"
                                                                            height="315"
                                                                            src="https://www.youtube.com/embed/dvykWW6JGO4"
                                                                            title="О продукции компании LR"
                                                                            frameborder="0"
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowfullscreen
                                                                        ></iframe>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeProd2"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnProd5"
                                                                        @click="btnProd5"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answProd5"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime4 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Далее
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd6"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime4 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Посмотрите несколько отзывов о продукте
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd7"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime4 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <p
                                                                    class="mb-5"
                                                                    style="width: 100%; max-width: 550px; height: auto;"
                                                                >
                                                                    <img style="width: 100%;" src="/img/leedbot/product/product1.jpg" alt=""/>
                                                                </p>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnProdOtz1"
                                                                    @click="btnProdOtz1"
                                                                >
                                                                    <v-icon class="mr-3">mdi-send</v-icon>
                                                                    Далее
                                                                </v-btn>

                                                                <transition name="fade">
                                                                    <p
                                                                        v-if="imgProduct2"
                                                                        class="mb-5"
                                                                        style="width: 100%; max-width: 550px; height: auto;"
                                                                    >
                                                                        <img style="width: 100%;" src="/img/leedbot/product/product2.jpg" alt=""/>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnProdOtz2"
                                                                            @click="btnProdOtz2"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </p>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div
                                                                        v-if="videoProduct3"
                                                                        class="mb-5"
                                                                    >
                                                                        <p
                                                                            class="mt-13 mb-5"
                                                                        >
                                                                            Бабушка не могла ходить, не могла сама одеться, болели суставы.
                                                                            После применения продукции LR начала двигаться самостоятельно.
                                                                        </p>
                                                                        <div class="thumb-wrap mb-10">
                                                                            <iframe
                                                                                width="560"
                                                                                height="315"
                                                                                src="https://www.youtube.com/embed/ZDBu0KPh2fk"
                                                                                title="Отзыв о продукции LR (3)"
                                                                                frameborder="0"
                                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                allowfullscreen
                                                                            ></iframe>
                                                                        </div>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnProdOtz3"
                                                                            @click="btnProdOtz3"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div
                                                                        v-if="videoProduct4"
                                                                        class="mb-5"
                                                                    >
                                                                        <p
                                                                            class="mt-13 mb-5"
                                                                        >
                                                                            Ушла глаукома, нормализовался вес, коленные суставы пришли в норму.
                                                                        </p>
                                                                        <div class="thumb-wrap mb-10">
                                                                            <iframe
                                                                                width="560"
                                                                                height="315"
                                                                                src="https://www.youtube.com/embed/rUBohM478XY"
                                                                                title="Отзыв о продукции LR (4)"
                                                                                frameborder="0"
                                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                allowfullscreen
                                                                            ></iframe>
                                                                        </div>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnProdOtz4"
                                                                            @click="btnProdOtz4"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div
                                                                        v-if="videoProduct5"
                                                                        class="mb-5"
                                                                    >
                                                                        <p
                                                                            class="mt-13 mb-5"
                                                                        >
                                                                            Уменьшилась раковая опухоль мозга, вернулось зрение и слух, ушли метостазы.
                                                                        </p>
                                                                        <div class="thumb-wrap mb-10">
                                                                            <iframe
                                                                                width="560"
                                                                                height="315"
                                                                                src="https://www.youtube.com/embed/zKMMQGILR5g"
                                                                                title="Отзыв о продукции LR (5)"
                                                                                frameborder="0"
                                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                allowfullscreen
                                                                            ></iframe>
                                                                        </div>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnProdOtz5"
                                                                            @click="btnProdOtz5"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div
                                                                        v-if="videoProduct6"
                                                                        class="mb-5"
                                                                    >
                                                                        <p
                                                                            class="mt-13 mb-5"
                                                                        >
                                                                            Женщина в возрасте перенесла Covid бессимптомно, укрепляла иммунитет продукцией LR.
                                                                        </p>
                                                                        <div class="thumb-wrap mb-10">
                                                                            <iframe
                                                                                width="560"
                                                                                height="315"
                                                                                src="https://www.youtube.com/embed/dwv4gwHiNto"
                                                                                title="Отзыв о продукции LR (6)"
                                                                                frameborder="0"
                                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                allowfullscreen
                                                                            ></iframe>
                                                                        </div>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnProdOtz6"
                                                                            @click="btnProdOtz6"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>

                                                                <transition name="fade">
                                                                    <div
                                                                        v-if="videoProduct7"
                                                                        class="mb-5"
                                                                    >
                                                                        <p
                                                                            class="mt-13 mb-5"
                                                                        >
                                                                            Ушла сезонная аллергия и вторичное бесплодие.
                                                                        </p>
                                                                        <div class="thumb-wrap mb-10">
                                                                            <iframe
                                                                                width="560"
                                                                                height="315"
                                                                                src="https://www.youtube.com/embed/KidZ_iXUnfs"
                                                                                title="Отзыв о продукции LR (7)"
                                                                                frameborder="0"
                                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                                allowfullscreen
                                                                            ></iframe>
                                                                        </div>
                                                                        <v-btn
                                                                            class="chat-btn"
                                                                            :disabled="disBtnProdOtz7"
                                                                            @click="btnProdOtz7"
                                                                        >
                                                                            <v-icon class="mr-3">mdi-send</v-icon>
                                                                            Далее
                                                                        </v-btn>
                                                                    </div>
                                                                </transition>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd8"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime5 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Почему наш продукт сейчас пользуется большим спросом
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd9"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime5 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p v-if="userData.gender === 'ж'">
                                                                        <!-- audio element -->
                                                                        <vue-player
                                                                            audio
                                                                            :sources="audioSources4g"
                                                                        />
                                                                    </p>
                                                                    <p v-else>
                                                                        <!-- audio element -->
                                                                        <vue-player
                                                                            audio
                                                                            :sources="audioSources4"
                                                                        />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeProd4"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnProd9"
                                                                        @click="btnProd9"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answProd9"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime6 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Далее
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd10"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime6 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p style="width: 100%; max-width: 450px; height: auto">
                                                                        <img style="width: 100%;" src="/img/leedbot/grafik_bad.jpeg" alt=""/>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd11"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime6 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Как видно из графика, жители стран, потребляющих БАДы, живут значительно дольше
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd12"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime6 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Посмотрите видео про БАДы от профессора Владимира Дадали
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd13"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime6 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message" style="width: 100%;">
                                                                    <div class="thumb-wrap">
                                                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/rQ-TlIVgXWo?si=nyYvdO01tvmrXuAy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeProd5"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnProd13"
                                                                        @click="btnProd13"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answProd13"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Далее
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd14"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Чем продукция LR отличается от других
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd15"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime7 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p v-if="userData.gender === 'ж'">
                                                                        <!-- audio element -->
                                                                        <vue-player
                                                                            audio
                                                                            :sources="audioSources5g"
                                                                        />
                                                                    </p>
                                                                    <p v-else>
                                                                        <!-- audio element -->
                                                                        <vue-player
                                                                            audio
                                                                            :sources="audioSources5"
                                                                        />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeProd6"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnProd15"
                                                                        @click="btnProd15"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answProd15"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime8 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Далее
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd16"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime8 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Чтобы узнать больше об Алоэ Вера, посмотрите видео
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd17"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime8 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message" style="width: 100%;">
                                                                    <div class="thumb-wrap">
                                                                        <iframe
                                                                            width="560"
                                                                            height="315"
                                                                            src="https://www.youtube.com/embed/6y6JxGx0xi0"
                                                                            title="Про Алое Вера от компании LR"
                                                                            frameborder="0"
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowfullscreen
                                                                        ></iframe>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <transition name="fade">
                                                                <div
                                                                    v-if="btnDaleeProd7"
                                                                    class="chat-controls"
                                                                >
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnProd17"
                                                                        @click="btnProd17"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Далее
                                                                    </v-btn>
                                                                </div>
                                                            </transition>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answProd17"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime9 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Далее
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesClientChat"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime10 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Хотите узнать больше о продукции компании LR,
                                                                        первыми узнавать об акциях и выгодных предложениях?
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Оставьте заявку и я добавлю вас в специальный чат
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        <span v-html="userData.about_chat"></span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls-end">
                                                                <v-btn
                                                                    class="chat-btn btn-skip"
                                                                    :disabled="disBtnChatSkip"
                                                                    @click="btnChatSkip"
                                                                >
                                                                    Пропустить
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnChat"
                                                                    @click="btnChat"
                                                                >
                                                                    Оставить заявку
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answChat"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime11 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    {{ answerClientChat }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesSendChat"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime11 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Выберите удобный для вас мессенджер и я отправлю вам ссылку на специальный чат
                                                                </div>
                                                                <div class="mt-7">
                                                                    <v-btn
                                                                        fab
                                                                        dark
                                                                        small
                                                                        class="mr-3"
                                                                        color="success"
                                                                        @click="btnWhatsapp"
                                                                    >
                                                                        <v-icon>mdi-whatsapp</v-icon>
                                                                    </v-btn>
<!--                                                                    <v-btn-->
<!--                                                                        fab-->
<!--                                                                        dark-->
<!--                                                                        small-->
<!--                                                                        class="mr-3"-->
<!--                                                                        color="blue"-->
<!--                                                                        @click="btnTelegram"-->
<!--                                                                    >-->
<!--                                                                        <v-icon>mdi-send</v-icon>-->
<!--                                                                    </v-btn>-->
                                                                    <v-btn
                                                                        fab
                                                                        dark
                                                                        small
                                                                        class="mr-3"
                                                                        color="deep-purple lighten-2"
                                                                        @click="btnViber"
                                                                    >
                                                                        <v-icon>fab fa-viber</v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                            <div v-if="btnWhatsappTrue">
                                                                <div class="chat-input">
                                                                    <v-text-field
                                                                        :disabled="disInpName"
                                                                        label="Введите свое имя"
                                                                        prepend-icon="mdi-face-man"
                                                                        type="text"
                                                                        v-model.trim="clientData.name"
                                                                        :error-messages="nameErrors"
                                                                        @input="$v.clientData.name.$touch()"
                                                                        @blur="$v.clientData.name.$touch()"
                                                                    />
                                                                    <v-text-field
                                                                        :disabled="disInpWhatsapp"
                                                                        label="Номер Whatsapp"
                                                                        prepend-icon="mdi-whatsapp"
                                                                        type="text"
                                                                        v-model.trim="clientData.phone_whatsapp"
                                                                        :error-messages="phoneWhatsappErrors"
                                                                        @input="$v.clientData.phone_whatsapp.$touch()"
                                                                        @blur="$v.clientData.phone_whatsapp.$touch()"
                                                                        hint="79123456789"
                                                                        persistent-hint
                                                                    />
                                                                </div>
                                                                <div class="chat-controls">
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnSendChat"
                                                                        @click="btnSendChat"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Отправить
                                                                    </v-btn>
                                                                </div>
                                                                <p class="privacy-text">
                                                                    Оставляя заявку, вы даете свое
                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                                                                    и принимаете
                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.
                                                                </p>
                                                            </div>
<!--                                                            <div v-if="btnTelegramTrue">-->
<!--                                                                <div class="chat-input">-->
<!--                                                                    <v-text-field-->
<!--                                                                        :disabled="disInpName"-->
<!--                                                                        label="Введите свое имя"-->
<!--                                                                        prepend-icon="mdi-face-man"-->
<!--                                                                        type="text"-->
<!--                                                                        v-model.trim="clientData.name"-->
<!--                                                                        :error-messages="nameErrors"-->
<!--                                                                        @input="$v.clientData.name.$touch()"-->
<!--                                                                        @blur="$v.clientData.name.$touch()"-->
<!--                                                                    />-->
<!--                                                                    <v-text-field-->
<!--                                                                        :disabled="disInpTelegram"-->
<!--                                                                        label="Имя пользователя в telegram"-->
<!--                                                                        prepend-icon="mdi-send"-->
<!--                                                                        type="text"-->
<!--                                                                        v-model.trim="clientData.telegram"-->
<!--                                                                        :error-messages="telegramErrors"-->
<!--                                                                        @input="$v.clientData.telegram.$touch()"-->
<!--                                                                        @blur="$v.clientData.telegram.$touch()"-->
<!--                                                                        hint="username"-->
<!--                                                                        persistent-hint-->
<!--                                                                    />-->
<!--                                                                </div>-->
<!--                                                                <div class="chat-controls">-->
<!--                                                                    <v-btn-->
<!--                                                                        class="chat-btn"-->
<!--                                                                        :disabled="disBtnSendChat"-->
<!--                                                                        @click="btnSendChat"-->
<!--                                                                    >-->
<!--                                                                        <v-icon class="mr-3">mdi-send</v-icon>-->
<!--                                                                        Отправить-->
<!--                                                                    </v-btn>-->
<!--                                                                </div>-->
<!--                                                                <p class="privacy-text">-->
<!--                                                                    Оставляя заявку, вы даете свое-->
<!--                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>-->
<!--                                                                    и принимаете-->
<!--                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.-->
<!--                                                                </p>-->
<!--                                                            </div>-->
                                                            <div v-if="btnViberTrue">
                                                                <div class="chat-input">
                                                                    <v-text-field
                                                                        :disabled="disInpName"
                                                                        label="Введите свое имя"
                                                                        prepend-icon="mdi-face-man"
                                                                        type="text"
                                                                        v-model.trim="clientData.name"
                                                                        :error-messages="nameErrors"
                                                                        @input="$v.clientData.name.$touch()"
                                                                        @blur="$v.clientData.name.$touch()"
                                                                    />
                                                                    <v-text-field
                                                                        :disabled="disInpViber"
                                                                        label="Номер Viber"
                                                                        prepend-icon="fab fa-viber"
                                                                        type="text"
                                                                        v-model.trim="clientData.phone_viber"
                                                                        :error-messages="phoneViberErrors"
                                                                        @input="$v.clientData.phone_viber.$touch()"
                                                                        @blur="$v.clientData.phone_viber.$touch()"
                                                                        hint="79123456789"
                                                                        persistent-hint
                                                                    />
                                                                </div>
                                                                <div class="chat-controls">
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnSendChat"
                                                                        @click="btnSendChat"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Отправить
                                                                    </v-btn>
                                                                </div>
                                                                <p class="privacy-text">
                                                                    Оставляя заявку, вы даете свое
                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                                                                    и принимаете
                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesTest"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime12 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">
                                                                        Вам было бы интересно пройти тест и узнать о состоянии своего здоровья?
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Этот тест разработан специалистами из Германии, и в Европе люди платят за него
                                                                        80 евро. Т.к. я сотрудничаю с немецкой компанией, то у меня есть возможность
                                                                        предоставить вам бесплатный доступ к тесту.
                                                                    </p>
                                                                    <p class="mb-5">
                                                                        Хотите бесплатно пройти онлайн-тест? Оставьте заявку.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls-end">
                                                                <v-btn
                                                                    class="chat-btn btn-skip"
                                                                    :disabled="disBtnTestSkip"
                                                                    @click="btnTestSkip"
                                                                >
                                                                    Пропустить
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnTest"
                                                                    @click="btnTest"
                                                                >
                                                                    Оставить заявку
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answTest"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime13 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    {{ answerTest }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesSendTest"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime13 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Выберите удобный для вас мессенджер и я отправлю вам ссылку на прохождение теста
                                                                </div>
                                                                <div class="mt-7">
                                                                    <v-btn
                                                                        fab
                                                                        dark
                                                                        small
                                                                        class="mr-3"
                                                                        color="success"
                                                                        @click="btnWhatsapp"
                                                                    >
                                                                        <v-icon>mdi-whatsapp</v-icon>
                                                                    </v-btn>
<!--                                                                    <v-btn-->
<!--                                                                        fab-->
<!--                                                                        dark-->
<!--                                                                        small-->
<!--                                                                        class="mr-3"-->
<!--                                                                        color="blue"-->
<!--                                                                        @click="btnTelegram"-->
<!--                                                                    >-->
<!--                                                                        <v-icon>mdi-send</v-icon>-->
<!--                                                                    </v-btn>-->
                                                                    <v-btn
                                                                        fab
                                                                        dark
                                                                        small
                                                                        class="mr-3"
                                                                        color="deep-purple lighten-2"
                                                                        @click="btnViber"
                                                                    >
                                                                        <v-icon>fab fa-viber</v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                            <div v-if="btnWhatsappTrue">
                                                                <div class="chat-input">
                                                                    <v-text-field
                                                                        :disabled="disInpName"
                                                                        label="Введите свое имя"
                                                                        prepend-icon="mdi-face-man"
                                                                        type="text"
                                                                        v-model.trim="clientData.name"
                                                                        :error-messages="nameErrors"
                                                                        @input="$v.clientData.name.$touch()"
                                                                        @blur="$v.clientData.name.$touch()"
                                                                    />
                                                                    <v-text-field
                                                                        :disabled="disInpWhatsapp"
                                                                        label="Номер Whatsapp"
                                                                        prepend-icon="mdi-whatsapp"
                                                                        type="text"
                                                                        v-model.trim="clientData.phone_whatsapp"
                                                                        :error-messages="phoneWhatsappErrors"
                                                                        @input="$v.clientData.phone_whatsapp.$touch()"
                                                                        @blur="$v.clientData.phone_whatsapp.$touch()"
                                                                        hint="79123456789"
                                                                        persistent-hint
                                                                    />
                                                                </div>
                                                                <div class="chat-controls">
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnSendTest"
                                                                        @click="btnSendTest"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Отправить
                                                                    </v-btn>
                                                                </div>
                                                                <p class="privacy-text">
                                                                    Оставляя заявку, вы даете свое
                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                                                                    и принимаете
                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.
                                                                </p>
                                                            </div>
<!--                                                            <div v-if="btnTelegramTrue">-->
<!--                                                                <div class="chat-input">-->
<!--                                                                    <v-text-field-->
<!--                                                                        :disabled="disInpName"-->
<!--                                                                        label="Введите свое имя"-->
<!--                                                                        prepend-icon="mdi-face-man"-->
<!--                                                                        type="text"-->
<!--                                                                        v-model.trim="clientData.name"-->
<!--                                                                        :error-messages="nameErrors"-->
<!--                                                                        @input="$v.clientData.name.$touch()"-->
<!--                                                                        @blur="$v.clientData.name.$touch()"-->
<!--                                                                    />-->
<!--                                                                    <v-text-field-->
<!--                                                                        :disabled="disInpTelegram"-->
<!--                                                                        label="Имя пользователя в telegram"-->
<!--                                                                        prepend-icon="mdi-send"-->
<!--                                                                        type="text"-->
<!--                                                                        v-model.trim="clientData.telegram"-->
<!--                                                                        :error-messages="telegramErrors"-->
<!--                                                                        @input="$v.clientData.telegram.$touch()"-->
<!--                                                                        @blur="$v.clientData.telegram.$touch()"-->
<!--                                                                        hint="username"-->
<!--                                                                        persistent-hint-->
<!--                                                                    />-->
<!--                                                                </div>-->
<!--                                                                <div class="chat-controls">-->
<!--                                                                    <v-btn-->
<!--                                                                        class="chat-btn"-->
<!--                                                                        :disabled="disBtnSendTest"-->
<!--                                                                        @click="btnSendTest"-->
<!--                                                                    >-->
<!--                                                                        <v-icon class="mr-3">mdi-send</v-icon>-->
<!--                                                                        Отправить-->
<!--                                                                    </v-btn>-->
<!--                                                                </div>-->
<!--                                                                <p class="privacy-text">-->
<!--                                                                    Оставляя заявку, вы даете свое-->
<!--                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>-->
<!--                                                                    и принимаете-->
<!--                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.-->
<!--                                                                </p>-->
<!--                                                            </div>-->
                                                            <div v-if="btnViberTrue">
                                                                <div class="chat-input">
                                                                    <v-text-field
                                                                        :disabled="disInpName"
                                                                        label="Введите свое имя"
                                                                        prepend-icon="mdi-face-man"
                                                                        type="text"
                                                                        v-model.trim="clientData.name"
                                                                        :error-messages="nameErrors"
                                                                        @input="$v.clientData.name.$touch()"
                                                                        @blur="$v.clientData.name.$touch()"
                                                                    />
                                                                    <v-text-field
                                                                        :disabled="disInpViber"
                                                                        label="Номер Viber"
                                                                        prepend-icon="fab fa-viber"
                                                                        type="text"
                                                                        v-model.trim="clientData.phone_viber"
                                                                        :error-messages="phoneViberErrors"
                                                                        @input="$v.clientData.phone_viber.$touch()"
                                                                        @blur="$v.clientData.phone_viber.$touch()"
                                                                        hint="79123456789"
                                                                        persistent-hint
                                                                    />
                                                                </div>
                                                                <div class="chat-controls">
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnSendTest"
                                                                        @click="btnSendTest"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Отправить
                                                                    </v-btn>
                                                                </div>
                                                                <p class="privacy-text">
                                                                    Оставляя заявку, вы даете свое
                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                                                                    и принимаете
                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesReqProd"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime14 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p>
                                                                        Хочешь узнать больше информации и получить бесплатную консультацию по продукту?
                                                                        Отправь заявку и я расскажу подробнее
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls">
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnReqProd"
                                                                    @click="btnReqProd"
                                                                >
                                                                    Оставить заявку
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnSkipProd"
                                                                    @click="btnSkipProd"
                                                                >
                                                                    Расскажи как можно заработать
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answReqProd"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime15 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    {{ answerReqProd }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesSendProd"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime15 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Выберите удобный для вас мессенджер и я свяжусь с вами в ближайшее время
                                                                </div>
                                                                <div class="mt-7">
                                                                    <v-btn
                                                                        fab
                                                                        dark
                                                                        small
                                                                        class="mr-3"
                                                                        color="success"
                                                                        @click="btnWhatsapp"
                                                                    >
                                                                        <v-icon>mdi-whatsapp</v-icon>
                                                                    </v-btn>
<!--                                                                    <v-btn-->
<!--                                                                        fab-->
<!--                                                                        dark-->
<!--                                                                        small-->
<!--                                                                        class="mr-3"-->
<!--                                                                        color="blue"-->
<!--                                                                        @click="btnTelegram"-->
<!--                                                                    >-->
<!--                                                                        <v-icon>mdi-send</v-icon>-->
<!--                                                                    </v-btn>-->
                                                                    <v-btn
                                                                        fab
                                                                        dark
                                                                        small
                                                                        class="mr-3"
                                                                        color="deep-purple lighten-2"
                                                                        @click="btnViber"
                                                                    >
                                                                        <v-icon>fab fa-viber</v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                            <div v-if="btnWhatsappTrue">
                                                                <div class="chat-input">
                                                                    <v-text-field
                                                                        :disabled="disInpName"
                                                                        label="Введите свое имя"
                                                                        prepend-icon="mdi-face-man"
                                                                        type="text"
                                                                        v-model.trim="clientData.name"
                                                                        :error-messages="nameErrors"
                                                                        @input="$v.clientData.name.$touch()"
                                                                        @blur="$v.clientData.name.$touch()"
                                                                    />
                                                                    <v-text-field
                                                                        :disabled="disInpWhatsapp"
                                                                        label="Номер Whatsapp"
                                                                        prepend-icon="mdi-whatsapp"
                                                                        type="text"
                                                                        v-model.trim="clientData.phone_whatsapp"
                                                                        :error-messages="phoneWhatsappErrors"
                                                                        @input="$v.clientData.phone_whatsapp.$touch()"
                                                                        @blur="$v.clientData.phone_whatsapp.$touch()"
                                                                        hint="79123456789"
                                                                        persistent-hint
                                                                    />
                                                                </div>
                                                                <div class="chat-controls">
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnSendProd"
                                                                        @click="btnSendProd"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Отправить
                                                                    </v-btn>
                                                                </div>
                                                                <p class="privacy-text">
                                                                    Оставляя заявку, вы даете свое
                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                                                                    и принимаете
                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.
                                                                </p>
                                                            </div>
<!--                                                            <div v-if="btnTelegramTrue">-->
<!--                                                                <div class="chat-input">-->
<!--                                                                    <v-text-field-->
<!--                                                                        :disabled="disInpName"-->
<!--                                                                        label="Введите свое имя"-->
<!--                                                                        prepend-icon="mdi-face-man"-->
<!--                                                                        type="text"-->
<!--                                                                        v-model.trim="clientData.name"-->
<!--                                                                        :error-messages="nameErrors"-->
<!--                                                                        @input="$v.clientData.name.$touch()"-->
<!--                                                                        @blur="$v.clientData.name.$touch()"-->
<!--                                                                    />-->
<!--                                                                    <v-text-field-->
<!--                                                                        :disabled="disInpTelegram"-->
<!--                                                                        label="Имя пользователя в telegram"-->
<!--                                                                        prepend-icon="mdi-send"-->
<!--                                                                        type="text"-->
<!--                                                                        v-model.trim="clientData.telegram"-->
<!--                                                                        :error-messages="telegramErrors"-->
<!--                                                                        @input="$v.clientData.telegram.$touch()"-->
<!--                                                                        @blur="$v.clientData.telegram.$touch()"-->
<!--                                                                        hint="username"-->
<!--                                                                        persistent-hint-->
<!--                                                                    />-->
<!--                                                                </div>-->
<!--                                                                <div class="chat-controls">-->
<!--                                                                    <v-btn-->
<!--                                                                        class="chat-btn"-->
<!--                                                                        :disabled="disBtnSendProd"-->
<!--                                                                        @click="btnSendProd"-->
<!--                                                                    >-->
<!--                                                                        <v-icon class="mr-3">mdi-send</v-icon>-->
<!--                                                                        Отправить-->
<!--                                                                    </v-btn>-->
<!--                                                                </div>-->
<!--                                                                <p class="privacy-text">-->
<!--                                                                    Оставляя заявку, вы даете свое-->
<!--                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>-->
<!--                                                                    и принимаете-->
<!--                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.-->
<!--                                                                </p>-->
<!--                                                            </div>-->
                                                            <div v-if="btnViberTrue">
                                                                <div class="chat-input">
                                                                    <v-text-field
                                                                        :disabled="disInpName"
                                                                        label="Введите свое имя"
                                                                        prepend-icon="mdi-face-man"
                                                                        type="text"
                                                                        v-model.trim="clientData.name"
                                                                        :error-messages="nameErrors"
                                                                        @input="$v.clientData.name.$touch()"
                                                                        @blur="$v.clientData.name.$touch()"
                                                                    />
                                                                    <v-text-field
                                                                        :disabled="disInpViber"
                                                                        label="Номер Viber"
                                                                        prepend-icon="fab fa-viber"
                                                                        type="text"
                                                                        v-model.trim="clientData.phone_viber"
                                                                        :error-messages="phoneViberErrors"
                                                                        @input="$v.clientData.phone_viber.$touch()"
                                                                        @blur="$v.clientData.phone_viber.$touch()"
                                                                        hint="79123456789"
                                                                        persistent-hint
                                                                    />
                                                                </div>
                                                                <div class="chat-controls">
                                                                    <v-btn
                                                                        class="chat-btn"
                                                                        :disabled="disBtnSendProd"
                                                                        @click="btnSendProd"
                                                                    >
                                                                        <v-icon class="mr-3">mdi-send</v-icon>
                                                                        Отправить
                                                                    </v-btn>
                                                                </div>
                                                                <p class="privacy-text">
                                                                    Оставляя заявку, вы даете свое
                                                                    <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                                                                    и принимаете
                                                                    <router-link :to="{name: 'Policy'}" target="_blank">Политику конфидециальности</router-link>.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd18"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime16 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">Заявка отправлена, я свяжусь с вами в ближайшее время</p>
                                                                    <p class="mb-3 mr-3">
                                                                        Или напишите мне:
                                                                    </p>
                                                                    <p>
                                                                        <a
                                                                            v-if="userData.phone_whatsapp"
                                                                            style="text-decoration: none"
                                                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="success"
                                                                            >
                                                                                <v-icon>mdi-whatsapp</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.phone_viber"
                                                                            style="text-decoration: none"
                                                                            :href="`viber://chat?number=${userData.phone_viber}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="deep-purple lighten-2"
                                                                            >
                                                                                <v-icon>fab fa-viber</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.telegram"
                                                                            style="text-decoration: none"
                                                                            :href="`${userData.telegram}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="blue"
                                                                            >
                                                                                <v-icon>mdi-send</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.fb_messenger"
                                                                            :href="`${userData.fb_messenger}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="light-blue"
                                                                            >
                                                                                <v-icon>mdi-facebook-messenger</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.vkontakte"
                                                                            :href="`${userData.vkontakte}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="blue darken-2"
                                                                            >
                                                                                <v-icon>fab fa-vk</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.odnoklassniki"
                                                                            :href="`${userData.odnoklassniki}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="orange darken-2"
                                                                            >
                                                                                <v-icon>mdi-odnoklassniki</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.instagram"
                                                                            :href="`${userData.instagram}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="pink darken-1"
                                                                            >
                                                                                <v-icon>mdi-instagram</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd19"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime16 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    Интересно узнать, как с нами можно еще и зарабатывать?
                                                                </div>
                                                            </div>
                                                            <div class="chat-controls-end">
                                                                <v-btn
                                                                    class="chat-btn btn-skip"
                                                                    :disabled="disBtnProd19Skip"
                                                                    @click="btnProd19Skip"
                                                                >
                                                                    Пропустить
                                                                </v-btn>
                                                                <v-btn
                                                                    class="chat-btn"
                                                                    :disabled="disBtnProd19"
                                                                    @click="btnProd19"
                                                                >
                                                                    Расскажи как заработать
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item item-user"
                                                        v-if="answProd19"
                                                    >
                                                        <div class="chat-avatar-user"></div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime17 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    {{ answerProd19 }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesProd20"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime17 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">Хорошо, больше не отвлекаю. Свяжусь с вами в ближайшее время</p>
                                                                    <p class="mb-3 mr-3">
                                                                        Или напишите мне:
                                                                    </p>
                                                                    <p>
                                                                        <a
                                                                            v-if="userData.phone_whatsapp"
                                                                            style="text-decoration: none"
                                                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="success"
                                                                            >
                                                                                <v-icon>mdi-whatsapp</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.phone_viber"
                                                                            style="text-decoration: none"
                                                                            :href="`viber://chat?number=${userData.phone_viber}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="deep-purple lighten-2"
                                                                            >
                                                                                <v-icon>fab fa-viber</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.telegram"
                                                                            style="text-decoration: none"
                                                                            :href="`${userData.telegram}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="blue"
                                                                            >
                                                                                <v-icon>mdi-send</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.fb_messenger"
                                                                            :href="`${userData.fb_messenger}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="light-blue"
                                                                            >
                                                                                <v-icon>mdi-facebook-messenger</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.vkontakte"
                                                                            :href="`${userData.vkontakte}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="blue darken-2"
                                                                            >
                                                                                <v-icon>fab fa-vk</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.odnoklassniki"
                                                                            :href="`${userData.odnoklassniki}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="orange darken-2"
                                                                            >
                                                                                <v-icon>mdi-odnoklassniki</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.instagram"
                                                                            :href="`${userData.instagram}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="pink darken-1"
                                                                            >
                                                                                <v-icon>mdi-instagram</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>

                                                <transition name="fade">
                                                    <div
                                                        class="chat-item"
                                                        v-if="mesEndLeedbot"
                                                    >
                                                        <div class="chat-avatar">
                                                            <img v-if="userData.avatar" :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                                                 alt=""
                                                            >
                                                        </div>
                                                        <div class="chat-block">
                                                            <div class="chat-date">
                                                                {{ nowDateTime.dateTime18 }}
                                                            </div>
                                                            <div class="chat-messages">
                                                                <div class="chat-message">
                                                                    <p class="mb-5">На данном этапе информации достаточно.</p>
                                                                    <p class="mb-5">Я свяжусь с вами в ближайшее время.</p>
                                                                    <p class="mb-3 mr-3">
                                                                        Или напишите мне:
                                                                    </p>
                                                                    <p>
                                                                        <a
                                                                            v-if="userData.phone_whatsapp"
                                                                            style="text-decoration: none"
                                                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="success"
                                                                            >
                                                                                <v-icon>mdi-whatsapp</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.phone_viber"
                                                                            style="text-decoration: none"
                                                                            :href="`viber://chat?number=${userData.phone_viber}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="deep-purple lighten-2"
                                                                            >
                                                                                <v-icon>fab fa-viber</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.telegram"
                                                                            style="text-decoration: none"
                                                                            :href="`${userData.telegram}`"
                                                                            target="_blank"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="blue"
                                                                            >
                                                                                <v-icon>mdi-send</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.fb_messenger"
                                                                            :href="`${userData.fb_messenger}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-3"
                                                                                color="light-blue"
                                                                            >
                                                                                <v-icon>mdi-facebook-messenger</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.vkontakte"
                                                                            :href="`${userData.vkontakte}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="blue darken-2"
                                                                            >
                                                                                <v-icon>fab fa-vk</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.odnoklassniki"
                                                                            :href="`${userData.odnoklassniki}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="orange darken-2"
                                                                            >
                                                                                <v-icon>mdi-odnoklassniki</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                        <a
                                                                            v-if="userData.instagram"
                                                                            :href="`${userData.instagram}`"
                                                                            target="_blank"
                                                                            style="text-decoration: none"
                                                                        >
                                                                            <v-btn
                                                                                fab
                                                                                dark
                                                                                small
                                                                                class="mr-4"
                                                                                color="pink darken-1"
                                                                            >
                                                                                <v-icon>mdi-instagram</v-icon>
                                                                            </v-btn>
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>
                                            </div>
                                            <div
                                                class="chat-typing"
                                                v-bind:class="{show: isShow}"
                                            >
                                                {{ userData.name }} печатает
                                                <div class="chat-typing-animate">
                                                    <div class="chat-typing-box">
                                                        ...
                                                        <img src="/img/leedbot/edit.svg" alt="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="scrollToMe"></div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
        </section>
    </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import {validationMixin} from 'vuelidate'
import {required, minLength, maxLength, numeric, helpers} from 'vuelidate/lib/validators'

const alpha = helpers.regex('alpha', /^[a-zA-Zа-яёА-ЯЁ]*$/)

export default {
    name: "Leedbot",
    mixins: [validationMixin],
    data: () => ({
        isShow: false,
        loading: true,
        lr_number: '',
        inst: '',
        errors: {},
        audioSources1: {
            'audio/mp3': '/audio/leedbot/audio_leedbot_biz1.mp3'
        },
        audioSources2: {
            'audio/mp3': '/audio/leedbot/audio_leedbot_biz2.mp3'
        },
        audioSources3: {
            'audio/mp3': '/audio/leedbot/audio_leedbot_prod1.mp3'
        },
        audioSources4: {
            'audio/mp3': '/audio/leedbot/audio_leedbot_prod2.mp3'
        },
        audioSources5: {
            'audio/mp3': '/audio/leedbot/audio_leedbot_prod3.mp3'
        },
        audioSources1g: {
            'audio/mp3': '/audio/leedbot/audio_leedbot_biz1g.mp3'
        },
        audioSources2g: {
            'audio/mp3': '/audio/leedbot/audio_leedbot_biz2g.mp3'
        },
        audioSources3g: {
            'audio/mp3': '/audio/leedbot/audio_leedbot_prod1g.mp3'
        },
        audioSources4g: {
            'audio/mp3': '/audio/leedbot/audio_leedbot_prod2g.mp3'
        },
        audioSources5g: {
            'audio/mp3': '/audio/leedbot/audio_leedbot_prod3g.mp3'
        },
        // playerOptions1: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/about_lr.mp4"
        //     }],
        //     poster: "/img/leedbot/proizvodstvo_video_poster.png",
        // },
        // playerOptions10: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/about_lr_prod.mp4"
        //     }],
        // },
        // playerOptions2: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/how_money_lr.mp4"
        //     }],
        //     poster: "/img/leedbot/how_money_poster.jpg",
        // },
        // playerOptions3: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/dadali_lr.mp4"
        //     }],
        //     poster: "/img/leedbot/dadali_video_poster.png",
        // },
        // playerOptions4: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/aloe_vera_lr.mp4"
        //     }],
        //     poster: "/img/leedbot/aloe_video_poster.png",
        // },
        // playerOptions5: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/product/videoProduct3.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions6: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/product/videoProduct4.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions7: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/product/videoProduct5.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions8: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/product/videoProduct6.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions9: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/product/videoProduct7.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions10_1: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/Ermatova_avto.mp4"
        //     }],
        //     poster: "",
        // },
        // playerOptions10_2: {
        //     muted: false,
        //     playbackRates: [0.7, 1.0, 1.5, 2.0],
        //     sources: [{
        //         type: "video/mp4",
        //         src: "/video/leedbot/avto-ot-lr.mp4"
        //     }],
        //     poster: "",
        // },
        nowDateTime: {
            dateTime1: '',
            dateTime2: '',
            dateTime3: '',
            dateTime4: '',
            dateTime5: '',
            dateTime6: '',
            dateTime7: '',
            dateTime8: '',
            dateTime9: '',
            dateTime10: '',
            dateTime11: '',
            dateTime12: '',
            dateTime13: '',
            dateTime14: '',
            dateTime15: '',
            dateTime16: '',
            dateTime17: '',
            dateTime18: '',
        },
        clientData: {
            branch: '',
            name: '',
            gender: '',
            age: '',
            target_a: '',
            income_want: '',
            phone: '',
            phone_whatsapp: '',
            phone_viber: '',
            // telegram: '',
            req: '',
            partner: '',
            instrument: 'Лидбот по бизнесу',
            step: 'Новый',
        },
        zayavka: false,
        answerBiz25: '',
        answerClientChat: '',
        answerTest: '',
        answerReqProd: '',
        answerProd19: '',
        cookieShow: true,
        mesFirst: false,
        mesSecond: false,
        disBtnBiz: false,
        disBtnProd: false,
        answBizProd: false,
        mesBiz1: false,
        disInpName: false,
        disBtnName: false,
        answName: false,
        answOk1: false,
        disBtnOk1: false,
        mesBiz2: false,
        mesBiz3: false,
        disBtnM: false,
        disBtnG: false,
        answGender: false,
        mesBiz4: false,
        disInpAge: false,
        disBtnAge: false,
        answAge: false,
        mesBiz5: false,
        disBtnNaim: false,
        disBtnFree: false,
        disBtnEnt: false,
        disBtnPfr: false,
        disBtnMlm: false,
        disBtnDekret: false,
        disBtnDom: false,
        answTarget: false,
        mesNaim1: false,
        mesNaim2: false,
        mesNaim3: false,
        mesEnt1: false,
        mesEnt2: false,
        mesEnt3: false,
        mesFree1: false,
        mesFree2: false,
        mesFree3: false,
        mesDekret1: false,
        mesDekret2: false,
        mesDekret3: false,
        mesMlm1: false,
        mesMlm2: false,
        mesMlm3: false,
        mesPfr1: false,
        mesPfr2: false,
        mesPfr3: false,
        mesDom1: false,
        mesDom2: false,
        mesDom3: false,
        disInpMoney: false,
        disBtnMoney: false,
        answMoney: false,
        mesBiz6: false,
        mesBiz7: false,
        disBtnBiz7: false,
        btnDaleeBiz1: false,
        answBiz7: false,
        mesBiz8: false,
        mesBiz9: false,
        disBtnOtzNaim1: false,
        disBtnOtzNaim2: false,
        disBtnOtzNaim3: false,
        disBtnOtzNaim4: false,
        disBtnOtzNaim5: false,
        disBtnOtzNaim6: false,
        disBtnOtzNaim7: false,
        imgNaim2: false,
        imgNaim3: false,
        imgNaim4: false,
        imgNaim5: false,
        imgNaim6: false,
        imgNaim7: false,
        disBtnOtzFree1: false,
        disBtnOtzFree2: false,
        disBtnOtzFree3: false,
        disBtnOtzFree4: false,
        disBtnOtzFree5: false,
        disBtnOtzFree6: false,
        disBtnOtzFree7: false,
        disBtnOtzFree8: false,
        imgFree2: false,
        imgFree3: false,
        imgFree4: false,
        imgFree5: false,
        imgFree6: false,
        imgFree7: false,
        imgFree8: false,
        disBtnOtzEnt1: false,
        disBtnOtzEnt2: false,
        disBtnOtzEnt3: false,
        disBtnOtzEnt4: false,
        disBtnOtzEnt5: false,
        disBtnOtzEnt6: false,
        imgEnt2: false,
        imgEnt3: false,
        imgEnt4: false,
        imgEnt5: false,
        imgEnt6: false,
        disBtnOtzPfr1: false,
        disBtnOtzPfr2: false,
        disBtnOtzPfr3: false,
        disBtnOtzPfr4: false,
        disBtnOtzPfr5: false,
        // imgPfr2: false,
        imgPfr3: false,
        imgPfr4: false,
        imgPfr5: false,
        disBtnOtzMlm1: false,
        disBtnOtzMlm2: false,
        disBtnOtzMlm3: false,
        disBtnOtzMlm4: false,
        disBtnOtzMlm5: false,
        disBtnOtzMlm6: false,
        imgMlm2: false,
        imgMlm3: false,
        imgMlm4: false,
        imgMlm5: false,
        imgMlm6: false,
        disBtnOtzDekret1: false,
        disBtnOtzDekret2: false,
        disBtnOtzDekret3: false,
        disBtnOtzDekret4: false,
        disBtnOtzDekret5: false,
        imgDekret2: false,
        imgDekret3: false,
        imgDekret4: false,
        imgDekret5: false,
        disBtnOtzDom1: false,
        disBtnOtzDom2: false,
        disBtnOtzDom3: false,
        disBtnOtzDom4: false,
        disBtnOtzDom5: false,
        disBtnOtzDom6: false,
        imgDom2: false,
        imgDom3: false,
        imgDom4: false,
        imgDom5: false,
        imgDom6: false,
        disBtnBiz9_1: false,
        btnDaleeBiz2_1: false,
        answBiz9_1: false,
        mesBiz10: false,
        mesBiz10_1: false,
        mesBiz11: false,
        disBtnBiz11: false,
        btnDaleeBiz3: false,
        // answBiz11: false,
        // mesBiz12: false,
        mesBiz13: false,
        disBtnBiz13: false,
        btnDaleeBiz4: false,
        answBiz13: false,
        mesBiz14: false,
        mesBiz15: false,
        disBtnBiz15: false,
        btnDaleeBiz5: false,
        btnDaleeBiz6_1: false,
        disBtnBiz17_1: false,
        answBiz15: false,
        mesBiz16: false,
        mesBiz17: false,
        disBtnOtzAvto1: false,
        disBtnOtzAvto2: false,
        disBtnOtzAvto3: false,
        disBtnOtzAvto4: false,
        disBtnOtzAvto5: false,
        imgAvto2: false,
        imgAvto3: false,
        imgAvto4: false,
        imgAvto5: false,
        mesBiz18: false,
        mesBiz19: false,
        disBtnBiz19: false,
        btnWhatsappTrue: false,
        // btnTelegramTrue: false,
        btnViberTrue: false,
        mesBiz20: false,
        mesBiz21: false,
        disBtnBizSkip21: false,
        disBtnBiz21: false,
        answBiz21: false,
        mesBiz22: false,
        mesProd1: false,
        mesProd2: false,
        mesProd3: false,
        disBtnProd3: false,
        btnDaleeProd1: false,
        answProd3: false,
        mesProd4: false,
        mesProd5: false,
        disBtnProd5: false,
        btnDaleeProd2: false,
        answProd5: false,
        mesProd6: false,
        mesProd7: false,
        disBtnProdOtz1: false,
        disBtnProdOtz2: false,
        disBtnProdOtz3: false,
        disBtnProdOtz4: false,
        disBtnProdOtz5: false,
        disBtnProdOtz6: false,
        disBtnProdOtz7: false,
        imgProduct2: false,
        videoProduct3: false,
        videoProduct4: false,
        videoProduct5: false,
        videoProduct6: false,
        videoProduct7: false,
        mesProd8: false,
        mesProd9: false,
        disBtnProd9: false,
        btnDaleeProd4: false,
        answProd9: false,
        mesProd10: false,
        mesProd11: false,
        mesProd12: false,
        mesProd13: false,
        disBtnProd13: false,
        btnDaleeProd5: false,
        answProd13: false,
        mesProd14: false,
        mesProd15: false,
        disBtnProd15: false,
        btnDaleeProd6: false,
        answProd15: false,
        mesProd16: false,
        mesProd17: false,
        disBtnProd17: false,
        btnDaleeProd7: false,
        answProd17: false,
        disInpWhatsapp: false,
        // disInpTelegram: false,
        disInpViber: false,
        mesClientChat: false,
        disBtnChatSkip: false,
        disBtnChat: false,
        answChat: false,
        mesSendChat: false,
        disBtnSendChat: false,
        mesTest: false,
        disBtnTestSkip: false,
        disBtnTest: false,
        answTest: false,
        mesSendTest: false,
        disBtnSendTest: false,
        mesReqProd: false,
        disBtnReqProd: false,
        disBtnSkipProd: false,
        answReqProd: false,
        mesSendProd: false,
        disBtnSendProd: false,
        mesProd18: false,
        mesProd19: false,
        disBtnProd19Skip: false,
        disBtnProd19: false,
        answProd19: false,
        mesProd20: false,
        mesEndLeedbot: false,
    }),
    metaInfo: {
        title: 'Бизнес-визитка партнера LR Health & Beauty',
        meta: [
            { vmid: 'description', property: 'description', content: 'Бизнес-визитка партнера LR Health & Beauty' },
            { vmid: 'og:title', property: 'og:title', content: 'Бизнес-визитка партнера LR Health & Beauty' },
            { vmid: 'og:description', property: 'og:description', content: 'Бизнес-визитка партнера LR Health & Beauty' },
            { vmid: 'og:image', property: 'og:image', content: '/img/newlvl_logo.png' },
        ],
    },
    created() {
        if (!this.$route.query.partner) {
            if (this.$cookies.isKey('newlvl_partner')) {
                const partnerNum = this.$cookies.get('newlvl_partner')
                this.$router.push ({name: 'Leedbot', query: { inst: 'leedbot', partner: partnerNum }})
            }
        } else {
            this.$cookies.set('newlvl_partner', this.$route.query.partner, '6m')
        }

        if (this.$cookies.isKey('newlvl_cookie')) {
            this.cookieShow = false
        }
        this.lr_number = this.$route.query.partner
        this.inst = this.$route.query.inst
    },
    computed: {
        ...mapGetters({
            userData: 'user/getUserLeedbot',
            userTestData: 'user/getIfUserTest',
        }),
        nameErrors() {
            const errors = []
            if (!this.$v.clientData.name.$dirty) return errors
            !this.$v.clientData.name.minLength && errors.push('Минимум 2 буквы')
            !this.$v.clientData.name.maxLength && errors.push('Максимум 30 букв')
            !this.$v.clientData.name.required && errors.push('Обязательно для заполнения')
            !this.$v.clientData.name.alpha && errors.push('Только буквы')
            return errors
        },
        ageErrors() {
            const errors = []
            if (!this.$v.clientData.age.$dirty) return errors
            !this.$v.clientData.age.minLength && errors.push('Минимум 1 цифра')
            !this.$v.clientData.age.maxLength && errors.push('Максимум 2 цифры')
            !this.$v.clientData.age.required && errors.push('Обязательно для заполнения')
            !this.$v.clientData.age.numeric && errors.push('Только цифры')
            return errors
        },
        moneyErrors() {
            const errors = []
            if (!this.$v.clientData.income_want.$dirty) return errors
            !this.$v.clientData.income_want.minLength && errors.push('Минимум 2 символа')
            !this.$v.clientData.income_want.maxLength && errors.push('Максимум 191 символ')
            !this.$v.clientData.income_want.required && errors.push('Обязательно для заполнения')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.clientData.phone.$dirty) return errors
            !this.$v.clientData.phone.numeric && errors.push('Только цифры')
            !this.$v.clientData.phone.minLength && errors.push('Минимум 5 цифр')
            !this.$v.clientData.phone.maxLength && errors.push('Максимум 20 цифр')
            !this.$v.clientData.phone.required && errors.push('Обязательно для заполнения')
            return errors
        },
        phoneWhatsappErrors() {
            const errors = []
            if (!this.$v.clientData.phone_whatsapp.$dirty) return errors
            !this.$v.clientData.phone_whatsapp.numeric && errors.push('Только цифры')
            !this.$v.clientData.phone_whatsapp.minLength && errors.push('Минимум 5 цифр')
            !this.$v.clientData.phone_whatsapp.maxLength && errors.push('Максимум 20 цифр')
            !this.$v.clientData.phone_whatsapp.required && errors.push('Обязательно для заполнения')
            return errors
        },
        phoneViberErrors() {
            const errors = []
            if (!this.$v.clientData.phone_viber.$dirty) return errors
            !this.$v.clientData.phone_viber.numeric && errors.push('Только цифры')
            !this.$v.clientData.phone_viber.minLength && errors.push('Минимум 5 цифр')
            !this.$v.clientData.phone_viber.maxLength && errors.push('Максимум 20 цифр')
            !this.$v.clientData.phone_viber.required && errors.push('Обязательно для заполнения')
            return errors
        },
        // telegramErrors() {
        //     const errors = []
        //     if (!this.$v.clientData.telegram.$dirty) return errors
        //     !this.$v.clientData.telegram.required && errors.push('Обязательно для заполнения')
        //     return errors
        // }
    },
    methods: {
        ...mapActions({
            showUser: 'user/showUserLeedbot',
            ifUserTest: 'user/ifUserTest',
            addNotification: 'application/addNotification',
            createProspect: 'user/addClient',
        }),
        zeroFirst(value) {
            if (value < 10) {
                value='0'+value
            }
            return value
        },
        dateTimeNow() {
            let currentTime = new Date()
            let day = this.zeroFirst(currentTime.getDate())
            let month = this.zeroFirst(currentTime.getMonth()+1)
            let hours = this.zeroFirst(currentTime.getHours())
            let minutes = this.zeroFirst(currentTime.getMinutes())

            if (month === '01') {
                month = 'января'
            }
            if (month === '02') {
                month = 'февраля'
            }
            if (month === '03') {
                month = 'марта'
            }
            if (month === '04') {
                month = 'апреля'
            }
            if (month === '05') {
                month = 'мая'
            }
            if (month === '06') {
                month = 'июня'
            }
            if (month === '07') {
                month = 'июля'
            }
            if (month === '08') {
                month = 'августа'
            }
            if (month === '09') {
                month = 'сентября'
            }
            if (month === '10') {
                month = 'октября'
            }
            if (month === '11') {
                month = 'ноября'
            }
            if (month === '12') {
                month = 'декабря'
            }

            return day+' '+month+',  '+hours+':'+minutes
        },
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('newlvl_cookie', 'accept', '12m')
        },
        scrollToMe () {
            let scrollBottom = document.getElementById("scrollToMe")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        showTyping() {
            let v = this
            setTimeout(function () {
                v.isShow = true
            }, 1000)
            this.showFirstMessage()
        },
        showTypingTxt() {
            let v = this
            setTimeout(function () {
                v.isShow = true
            }, 1000)
        },
        showFirstMessage() {
            let v = this
            this.nowDateTime.dateTime1 = this.dateTimeNow()
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesFirst = true
            }, 3300)
            this.showSecondMessage()
        },
        showSecondMessage() {
            let v = this
            setTimeout(function () {
                v.isShow = true
            }, 6300)
            setTimeout(function () {
                v.isShow = false
            }, 8300)
            setTimeout(function () {
                v.mesSecond = true
            }, 8800)
        },
        btnBiz() {
            this.answBizProd = true
            this.disBtnBiz = true
            this.disBtnProd = true
            this.clientData.branch = 'бизнес'
            this.showTypingTxt()
            this.showMesBiz1()
        },
        showMesBiz1() {
            let v = this
            this.nowDateTime.dateTime2 = this.dateTimeNow()
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz1 = true
            }, 3500)
        },
        myName() {
            this.$v.clientData.name.$touch()
            if (!this.$v.clientData.name.$invalid) {
                this.nowDateTime.dateTime3 = this.dateTimeNow()
                this.disBtnName = true
                this.disInpName = true
                this.answName = true
                this.showTypingTxt()
                this.showMesBiz2()
            }
        },
        showMesBiz2() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz2 = true
            }, 3500)
        },
        btnOk1() {
            this.nowDateTime.dateTime4 = this.dateTimeNow()
            this.disBtnOk1 = true
            this.answOk1 = true
            this.showTypingTxt()
            this.showMesBiz3()
        },
        showMesBiz3() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz3 = true
            }, 3500)
        },
        btnM() {
            this.nowDateTime.dateTime5 = this.dateTimeNow()
            this.disBtnM = true
            this.disBtnG = true
            this.clientData.gender = 'м'
            this.answGender = true
            this.showTypingTxt()
            this.showMesBiz4()
        },
        btnG() {
            this.nowDateTime.dateTime5 = this.dateTimeNow()
            this.disBtnM = true
            this.disBtnG = true
            this.clientData.gender = 'ж'
            this.answGender = true
            this.showTypingTxt()
            this.showMesBiz4()
        },
        showMesBiz4() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz4 = true
            }, 3500)
        },
        myAge() {
            this.$v.clientData.age.$touch()
            if (!this.$v.clientData.age.$invalid) {
                this.nowDateTime.dateTime6 = this.dateTimeNow()
                this.disInpAge = true
                this.disBtnAge = true
                this.answAge = true
                this.showTypingTxt()
                this.showMesBiz5()
            }
        },
        showMesBiz5() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz5 = true
            }, 3500)
        },
        targetNaim() {
            this.nowDateTime.dateTime7 = this.dateTimeNow()
            this.disBtnNaim = true
            this.disBtnFree = true
            this.disBtnEnt = true
            this.disBtnPfr = true
            this.disBtnMlm = true
            this.disBtnDekret = true
            this.disBtnDom = true
            this.clientData.target_a = 'Работаю по найму'
            this.answTarget = true
            this.showTypingTxt()
            this.showMesNaim()
        },
        showMesNaim() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesNaim1 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 10500)
            setTimeout(function () {
                v.isShow = false
            }, 12500)
            setTimeout(function () {
                v.mesNaim2 = true
            }, 13000)
            setTimeout(function () {
                v.isShow = true
            }, 23000)
            setTimeout(function () {
                v.isShow = false
            }, 25000)
            setTimeout(function () {
                v.mesNaim3 = true
            }, 25500)
        },
        targetFree() {
            this.nowDateTime.dateTime7 = this.dateTimeNow()
            this.disBtnNaim = true
            this.disBtnFree = true
            this.disBtnEnt = true
            this.disBtnPfr = true
            this.disBtnMlm = true
            this.disBtnDekret = true
            this.disBtnDom = true
            this.clientData.target_a = 'Работаю на себя'
            this.answTarget = true
            this.showTypingTxt()
            this.showMesFree()
        },
        showMesFree() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesFree1 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 16500)
            setTimeout(function () {
                v.isShow = false
            }, 18500)
            setTimeout(function () {
                v.mesFree2 = true
            }, 19000)
            setTimeout(function () {
                v.isShow = true
            }, 32000)
            setTimeout(function () {
                v.isShow = false
            }, 34000)
            setTimeout(function () {
                v.mesFree3 = true
            }, 34500)
        },
        targetEnt() {
            this.nowDateTime.dateTime7 = this.dateTimeNow()
            this.disBtnNaim = true
            this.disBtnFree = true
            this.disBtnEnt = true
            this.disBtnPfr = true
            this.disBtnMlm = true
            this.disBtnDekret = true
            this.disBtnDom = true
            this.clientData.target_a = 'Свой бизнес'
            this.answTarget = true
            this.showTypingTxt()
            this.showMesEnt()
        },
        showMesEnt() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesEnt1 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 16500)
            setTimeout(function () {
                v.isShow = false
            }, 18500)
            setTimeout(function () {
                v.mesEnt2 = true
            }, 19000)
            setTimeout(function () {
                v.isShow = true
            }, 40000)
            setTimeout(function () {
                v.isShow = false
            }, 42000)
            setTimeout(function () {
                v.mesEnt3 = true
            }, 42500)
        },
        targetPfr() {
            this.nowDateTime.dateTime7 = this.dateTimeNow()
            this.disBtnNaim = true
            this.disBtnFree = true
            this.disBtnEnt = true
            this.disBtnPfr = true
            this.disBtnMlm = true
            this.disBtnDekret = true
            this.disBtnDom = true
            this.clientData.target_a = 'На пенсии'
            this.answTarget = true
            this.showTypingTxt()
            this.showMesPfr()
        },
        showMesPfr() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesPfr1 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 19500)
            setTimeout(function () {
                v.isShow = false
            }, 21500)
            setTimeout(function () {
                v.mesPfr2 = true
            }, 22000)
            setTimeout(function () {
                v.isShow = true
            }, 33000)
            setTimeout(function () {
                v.isShow = false
            }, 35000)
            setTimeout(function () {
                v.mesPfr3 = true
            }, 35500)
        },
        targetMlm() {
            this.nowDateTime.dateTime7 = this.dateTimeNow()
            this.disBtnNaim = true
            this.disBtnFree = true
            this.disBtnEnt = true
            this.disBtnPfr = true
            this.disBtnMlm = true
            this.disBtnDekret = true
            this.disBtnDom = true
            this.clientData.target_a = 'Сетевик'
            this.answTarget = true
            this.showTypingTxt()
            this.showMesMlm()
        },
        showMesMlm() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesMlm1 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 15500)
            setTimeout(function () {
                v.isShow = false
            }, 17500)
            setTimeout(function () {
                v.mesMlm2 = true
            }, 18000)
            setTimeout(function () {
                v.isShow = true
            }, 40000)
            setTimeout(function () {
                v.isShow = false
            }, 42000)
            setTimeout(function () {
                v.mesMlm3 = true
            }, 42500)
        },
        targetDekret() {
            this.nowDateTime.dateTime7 = this.dateTimeNow()
            this.disBtnNaim = true
            this.disBtnFree = true
            this.disBtnEnt = true
            this.disBtnPfr = true
            this.disBtnMlm = true
            this.disBtnDekret = true
            this.disBtnDom = true
            this.clientData.target_a = 'В декрете'
            this.answTarget = true
            this.showTypingTxt()
            this.showMesDekret()
        },
        showMesDekret() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesDekret1 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 18500)
            setTimeout(function () {
                v.isShow = false
            }, 22500)
            setTimeout(function () {
                v.mesDekret2 = true
            }, 23000)
            setTimeout(function () {
                v.isShow = true
            }, 39000)
            setTimeout(function () {
                v.isShow = false
            }, 43000)
            setTimeout(function () {
                v.mesDekret3 = true
            }, 43500)
        },
        targetDom() {
            this.nowDateTime.dateTime7 = this.dateTimeNow()
            this.disBtnNaim = true
            this.disBtnFree = true
            this.disBtnEnt = true
            this.disBtnPfr = true
            this.disBtnMlm = true
            this.disBtnDekret = true
            this.disBtnDom = true
            this.clientData.target_a = 'Домохозяйка'
            this.answTarget = true
            this.showTypingTxt()
            this.showMesDom()
        },
        showMesDom() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesDom1 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 22500)
            setTimeout(function () {
                v.isShow = false
            }, 24500)
            setTimeout(function () {
                v.mesDom2 = true
            }, 25000)
            setTimeout(function () {
                v.isShow = true
            }, 38000)
            setTimeout(function () {
                v.isShow = false
            }, 40000)
            setTimeout(function () {
                v.mesDom3 = true
            }, 40500)
        },
        btnMoney() {
            this.$v.clientData.income_want.$touch()
            if (!this.$v.clientData.income_want.$invalid) {
                this.nowDateTime.dateTime8= this.dateTimeNow()
                this.disInpMoney = true
                this.disBtnMoney = true
                this.answMoney = true
                this.showTypingTxt()
                this.showMesBiz6()
            }
        },
        showMesBiz6() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz6 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 6500)
            setTimeout(function () {
                v.isShow = false
            }, 8500)
            setTimeout(function () {
                v.mesBiz7 = true
            }, 9000)
            setTimeout(function () {
                v.btnDaleeBiz1 = true
            }, 19000)
        },
        btnBiz7() {
            this.nowDateTime.dateTime9= this.dateTimeNow()
            this.disBtnBiz7 = true
            this.answBiz7 = true
            this.showTypingTxt()
            this.showMesBiz8()
        },
        showMesBiz8() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz8 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 5500)
            setTimeout(function () {
                v.isShow = false
            }, 7500)
            setTimeout(function () {
                v.mesBiz9 = true
            }, 8000)
        },
        btnOtzNaim1() {
            this.disBtnOtzNaim1 = true
            this.imgNaim2 = true
        },
        btnOtzNaim2() {
            this.disBtnOtzNaim2 = true
            this.imgNaim3 = true
        },
        btnOtzNaim3() {
            this.disBtnOtzNaim3 = true
            this.imgNaim4 = true
        },
        btnOtzNaim4() {
            this.disBtnOtzNaim4 = true
            this.imgNaim5 = true
        },
        btnOtzNaim5() {
            this.disBtnOtzNaim5 = true
            this.imgNaim6 = true
        },
        btnOtzNaim6() {
            this.disBtnOtzNaim6 = true
            this.imgNaim7 = true
        },
        btnOtzNaim7() {
            this.nowDateTime.dateTime10= this.dateTimeNow()
            this.disBtnOtzNaim6 = true
            this.showTypingTxt()
            this.showMesBiz10_1()
        },
        btnOtzFree1() {
            this.disBtnOtzFree1 = true
            this.imgFree2 = true
        },
        btnOtzFree2() {
            this.disBtnOtzFree2 = true
            this.imgFree3 = true
        },
        btnOtzFree3() {
            this.disBtnOtzFree3 = true
            this.imgFree4 = true
        },
        btnOtzFree4() {
            this.disBtnOtzFree4 = true
            this.imgFree5 = true
        },
        btnOtzFree5() {
            this.disBtnOtzFree5 = true
            this.imgFree6 = true
        },
        btnOtzFree6() {
            this.disBtnOtzFree6 = true
            this.imgFree7 = true
        },
        btnOtzFree7() {
            this.disBtnOtzFree7 = true
            this.imgFree8 = true
        },
        btnOtzFree8() {
            this.nowDateTime.dateTime10= this.dateTimeNow()
            this.disBtnOtzFree8 = true
            this.showTypingTxt()
            this.showMesBiz10_1()
        },
        btnOtzEnt1() {
            this.disBtnOtzEnt1 = true
            this.imgEnt2 = true
        },
        btnOtzEnt2() {
            this.disBtnOtzEnt2 = true
            this.imgEnt3 = true
        },
        btnOtzEnt3() {
            this.disBtnOtzEnt3 = true
            this.imgEnt4 = true
        },
        btnOtzEnt4() {
            this.disBtnOtzEnt4 = true
            this.imgEnt5 = true
        },
        btnOtzEnt5() {
            this.disBtnOtzEnt5 = true
            this.imgEnt6 = true
        },
        btnOtzEnt6() {
            this.nowDateTime.dateTime10= this.dateTimeNow()
            this.disBtnOtzEnt6 = true
            this.showTypingTxt()
            this.showMesBiz10_1()
        },
        btnOtzPfr1() {
            this.disBtnOtzPfr1 = true
            this.imgPfr3 = true
        },
        // btnOtzPfr2() {
        //     this.disBtnOtzPfr2 = true
        //     this.imgPfr3 = true
        // },
        btnOtzPfr3() {
            this.disBtnOtzPfr3 = true
            this.imgPfr4 = true
        },
        btnOtzPfr4() {
            this.disBtnOtzPfr4 = true
            this.imgPfr5 = true
        },
        btnOtzPfr5() {
            this.nowDateTime.dateTime10= this.dateTimeNow()
            this.disBtnOtzPfr5 = true
            this.showTypingTxt()
            this.showMesBiz10_1()
        },
        btnOtzMlm1() {
            this.disBtnOtzMlm1 = true
            this.imgMlm2 = true
        },
        btnOtzMlm2() {
            this.disBtnOtzMlm2 = true
            this.imgMlm3 = true
        },
        btnOtzMlm3() {
            this.disBtnOtzMlm3 = true
            this.imgMlm4 = true
        },
        btnOtzMlm4() {
            this.disBtnOtzMlm4 = true
            this.imgMlm5 = true
        },
        btnOtzMlm5() {
            this.disBtnOtzMlm5 = true
            this.imgMlm6 = true
        },
        btnOtzMlm6() {
            this.nowDateTime.dateTime10= this.dateTimeNow()
            this.disBtnOtzMlm6 = true
            this.showTypingTxt()
            this.showMesBiz10_1()
        },
        btnOtzDekret1() {
            this.disBtnOtzDekret1 = true
            this.imgDekret2 = true
        },
        btnOtzDekret2() {
            this.disBtnOtzDekret2 = true
            this.imgDekret3 = true
        },
        btnOtzDekret3() {
            this.disBtnOtzDekret3 = true
            this.imgDekret4 = true
        },
        btnOtzDekret4() {
            this.disBtnOtzDekret4 = true
            this.imgDekret5 = true
        },
        btnOtzDekret5() {
            this.nowDateTime.dateTime10= this.dateTimeNow()
            this.disBtnOtzDekret5 = true
            this.showTypingTxt()
            this.showMesBiz10_1()
        },
        btnOtzDom1() {
            this.disBtnOtzDom1 = true
            this.imgDom2 = true
        },
        btnOtzDom2() {
            this.disBtnOtzDom2 = true
            this.imgDom3 = true
        },
        btnOtzDom3() {
            this.disBtnOtzDom3 = true
            this.imgDom4 = true
        },
        btnOtzDom4() {
            this.disBtnOtzDom4 = true
            this.imgDom5 = true
        },
        btnOtzDom5() {
            this.disBtnOtzDom5 = true
            this.imgDom6 = true
        },
        btnOtzDom6() {
            this.nowDateTime.dateTime10= this.dateTimeNow()
            this.disBtnOtzDom6 = true
            this.showTypingTxt()
            this.showMesBiz10_1()
        },
        showMesBiz10_1() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz10_1 = true
            }, 3500)
            setTimeout(function () {
                v.btnDaleeBiz2_1 = true
            }, 10000)
        },
        btnBiz9_1() {
            this.nowDateTime.dateTime10= this.dateTimeNow()
            this.disBtnBiz9_1 = true
            this.answBiz9_1 = true
            this.showTypingTxt()
            this.showMesBiz10()
        },
        showMesBiz10() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz10 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 5500)
            setTimeout(function () {
                v.isShow = false
            }, 7500)
            setTimeout(function () {
                v.mesBiz11 = true
            }, 8000)
            setTimeout(function () {
                v.btnDaleeBiz3 = true
            }, 18000)
        },
        btnBiz11() {
            this.nowDateTime.dateTime11= this.dateTimeNow()
            this.disBtnBiz11 = true
            // this.answBiz11 = true
            this.showTypingTxt()
            this.showMesBiz12()
        },
        showMesBiz12() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            // setTimeout(function () {
            //     v.mesBiz12 = true
            // }, 3500)
            // setTimeout(function () {
            //     v.isShow = true
            // }, 5500)
            // setTimeout(function () {
            //     v.isShow = false
            // }, 7500)
            setTimeout(function () {
                v.mesBiz13 = true
            }, 3500)
            setTimeout(function () {
                v.btnDaleeBiz4 = true
            }, 13500)
        },
        btnBiz13() {
            this.nowDateTime.dateTime12= this.dateTimeNow()
            this.disBtnBiz13 = true
            this.answBiz13 = true
            this.showTypingTxt()
            this.showMesBiz14()
        },
        showMesBiz14() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz14 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 5500)
            setTimeout(function () {
                v.isShow = false
            }, 7500)
            setTimeout(function () {
                v.mesBiz15 = true
            }, 8000)
            setTimeout(function () {
                v.btnDaleeBiz5 = true
            }, 18000)
        },
        btnBiz15() {
            this.nowDateTime.dateTime13= this.dateTimeNow()
            this.disBtnBiz15 = true
            this.answBiz15 = true
            this.showTypingTxt()
            this.showMesBiz16()
        },
        showMesBiz16() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz16 = true
            }, 3500)
            setTimeout(function () {
                v.btnDaleeBiz6_1 = true
            }, 13500)
        },
        btnBiz17_1() {
            this.nowDateTime.dateTime13= this.dateTimeNow()
            this.disBtnBiz17_1= true
            this.showTypingTxt()
            this.showMesBiz16_1()
        },
        showMesBiz16_1() {
            let v = this
            setTimeout(function () {
                v.isShow = true
            }, 5500)
            setTimeout(function () {
                v.isShow = false
            }, 7500)
            setTimeout(function () {
                v.mesBiz17 = true
            }, 8000)
        },
        btnOtzAvto1() {
            this.disBtnOtzAvto1 = true
            this.imgAvto2 = true
        },
        btnOtzAvto2() {
            this.disBtnOtzAvto2 = true
            this.imgAvto3 = true
        },
        btnOtzAvto3() {
            this.disBtnOtzAvto3 = true
            this.imgAvto4 = true
        },
        btnOtzAvto4() {
            this.disBtnOtzAvto4 = true
            this.imgAvto5 = true
        },
        btnOtzAvto5() {
            this.nowDateTime.dateTime14= this.dateTimeNow()
            this.disBtnOtzAvto5 = true
            this.showTypingTxt()
            this.showMesBiz18()
        },
        showMesBiz18() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz18 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 8500)
            setTimeout(function () {
                v.isShow = false
            }, 10500)
            if (!this.zayavka) {
                setTimeout(function () {
                    v.mesBiz19 = true
                }, 11000)
            } else {
                setTimeout(function () {
                    v.mesEndLeedbot = true
                }, 11000)
            }
        },
        btnWhatsapp() {
            this.btnWhatsappTrue = true
            this.btnViberTrue = false
            // this.btnTelegramTrue = false
        },
        // btnTelegram() {
        //     this.btnWhatsappTrue = false
        //     this.btnViberTrue = false
        //     // this.btnTelegramTrue = true
        // },
        btnViber() {
            this.btnWhatsappTrue = false
            this.btnViberTrue = true
            // this.btnTelegramTrue = false
        },
        btnSendBiz() {
            this.$v.clientData.phone_whatsapp.$touch() || this.$v.clientData.phone_viber.$touch()
            if (!this.$v.clientData.phone_whatsapp.$invalid || !this.$v.clientData.phone_viber.$invalid) {
                this.nowDateTime.dateTime15 = this.dateTimeNow()
                this.disBtnBiz19 = true
                this.disInpWhatsapp = true
                // this.disInpTelegram = true
                this.disInpViber = true
                this.clientData.req = 'Заявка на бизнес'
                this.createProspect(this.clientData)
                    .then((response) => {
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.addNotification({
                                show: true,
                                text: response.data.message,
                                color: 'success'
                            })
                            this.showTypingTxt()
                            this.showMesBiz20()
                            this.zayavka = true
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 422:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            case 500:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Ошибка валидации',
                })
            }
        },
        showMesBiz20() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz20 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 5500)
            setTimeout(function () {
                v.isShow = false
            }, 7500)
            setTimeout(function () {
                v.mesBiz21 = true
            }, 8000)
        },
        btnBizSkip21() {
            this.nowDateTime.dateTime16= this.dateTimeNow()
            this.disBtnBizSkip21 = true
            this.disBtnBiz21 = true
            this.answBiz21 = true
            this.answerBiz25 = 'Пропустить'
            this.showTypingTxt()
            this.showMesBiz21()
        },
        showMesBiz21() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesBiz22 = true
            }, 3500)
        },
        btnBiz21() {
            this.nowDateTime.dateTime16= this.dateTimeNow()
            this.disBtnBizSkip21 = true
            this.disBtnBiz21 = true
            this.answBiz21 = true
            this.answerBiz25 = 'Расскажи про продукт'
            this.mesFirst = false
            this.mesSecond = false
            this.answBizProd = false
            this.mesBiz1 = false
            this.answName = false
            this.answOk1 = false
            this.mesBiz2 = false
            this.mesBiz3 = false
            this.answGender = false
            this.mesBiz4 = false
            this.answAge = false
            this.mesBiz5 = false
            this.answTarget = false
            this.mesNaim1 = false
            this.mesNaim2 = false
            this.mesNaim3 = false
            this.mesEnt1 = false
            this.mesEnt2 = false
            this.mesEnt3 = false
            this.mesFree1 = false
            this.mesFree2 = false
            this.mesFree3 = false
            this.mesDekret1 = false
            this.mesDekret2 = false
            this.mesDekret3 = false
            this.mesMlm1 = false
            this.mesMlm2 = false
            this.mesMlm3 = false
            this.mesPfr1 = false
            this.mesPfr2 = false
            this.mesPfr3 = false
            this.mesDom1 = false
            this.mesDom2 = false
            this.mesDom3 = false
            this.answMoney = false
            this.mesBiz6 = false
            this.mesBiz7 = false
            this.answBiz7 = false
            this.mesBiz8 = false
            this.mesBiz9 = false
            this.answBiz9 = false
            this.answBiz9_1 = false
            this.mesBiz10 = false
            this.mesBiz10_1 = false
            this.answBiz17_1 = false
            this.btnDaleeBiz2_1 = false
            this.mesBiz11 = false
            this.answBiz11 = false
            this.mesBiz12 = false
            this.mesBiz13 = false
            this.answBiz13 = false
            this.mesBiz14 = false
            this.mesBiz15 = false
            this.answBiz15 = false
            this.mesBiz16 = false
            this.mesBiz17 = false
            this.answBiz17 = false
            this.mesBiz18 = false
            this.mesBiz19 = false
            this.mesBiz20 = false
            this.mesBiz21 = false
            this.answBiz21 = false
            this.mesBiz22 = false
            this.showTypingTxt()
            this.showMesProd1()
        },
        btnProd() {
            this.answBizProd = true
            this.disBtnBiz = true
            this.disBtnProd = true
            this.clientData.branch = 'продукт'
            this.showTypingTxt()
            this.showMesProd1()
        },
        showMesProd1() {
            let v = this
            this.nowDateTime.dateTime2 = this.dateTimeNow()
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesProd1 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 12500)
            setTimeout(function () {
                v.isShow = false
            }, 14500)
            setTimeout(function () {
                v.mesProd2 = true
            }, 15000)
            setTimeout(function () {
                v.isShow = true
            }, 16000)
            setTimeout(function () {
                v.isShow = false
            }, 18000)
            setTimeout(function () {
                v.mesProd3 = true
            }, 18500)
            setTimeout(function () {
                v.btnDaleeProd1 = true
            }, 28500)
        },
        btnProd3() {
            this.nowDateTime.dateTime3 = this.dateTimeNow()
            this.answProd3= true
            this.disBtnProd3 = true
            this.showTypingTxt()
            this.showMesProd4()
        },
        showMesProd4() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesProd4 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 4500)
            setTimeout(function () {
                v.isShow = false
            }, 6500)
            setTimeout(function () {
                v.mesProd5 = true
            }, 7000)
            setTimeout(function () {
                v.btnDaleeProd2 = true
            }, 17000)
        },
        btnProd5() {
            this.nowDateTime.dateTime4 = this.dateTimeNow()
            this.answProd5= true
            this.disBtnProd5 = true
            this.showTypingTxt()
            this.showMesProd6()
        },
        showMesProd6() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesProd6 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 4500)
            setTimeout(function () {
                v.isShow = false
            }, 6500)
            setTimeout(function () {
                v.mesProd7 = true
            }, 7000)
        },
        btnProdOtz1() {
            this.disBtnProdOtz1 = true
            this.imgProduct2 = true
        },
        btnProdOtz2() {
            this.disBtnProdOtz2 = true
            this.videoProduct3 = true
        },
        btnProdOtz3() {
            this.disBtnProdOtz3 = true
            this.videoProduct4 = true
        },
        btnProdOtz4() {
            this.disBtnProdOtz4 = true
            this.videoProduct5 = true
        },
        btnProdOtz5() {
            this.disBtnProdOtz5 = true
            this.videoProduct6 = true
        },
        btnProdOtz6() {
            this.disBtnProdOtz6 = true
            this.videoProduct7 = true
        },
        btnProdOtz7() {
            this.nowDateTime.dateTime5 = this.dateTimeNow()
            this.disBtnProdOtz7 = true
            this.showTypingTxt()
            this.showMesProd8()
        },
        showMesProd8() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesProd8 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 4500)
            setTimeout(function () {
                v.isShow = false
            }, 6500)
            setTimeout(function () {
                v.mesProd9 = true
            }, 7000)
            setTimeout(function () {
                v.btnDaleeProd4 = true
            }, 17000)
        },
        btnProd9() {
            this.nowDateTime.dateTime6 = this.dateTimeNow()
            this.answProd9= true
            this.disBtnProd9 = true
            this.showTypingTxt()
            this.showMesProd10()
        },
        showMesProd10() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesProd10 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 4500)
            setTimeout(function () {
                v.isShow = false
            }, 6500)
            setTimeout(function () {
                v.mesProd11 = true
            }, 7000)
            setTimeout(function () {
                v.isShow = true
            }, 8000)
            setTimeout(function () {
                v.isShow = false
            }, 10000)
            setTimeout(function () {
                v.mesProd12 = true
            }, 10500)
            setTimeout(function () {
                v.isShow = true
            }, 11500)
            setTimeout(function () {
                v.isShow = false
            }, 13500)
            setTimeout(function () {
                v.mesProd13 = true
            }, 14000)
            setTimeout(function () {
                v.btnDaleeProd5 = true
            }, 24000)
        },
        btnProd13() {
            this.nowDateTime.dateTime7 = this.dateTimeNow()
            this.answProd13= true
            this.disBtnProd13 = true
            this.showTypingTxt()
            this.showMesProd14()
        },
        showMesProd14() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesProd14 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 4500)
            setTimeout(function () {
                v.isShow = false
            }, 6500)
            setTimeout(function () {
                v.mesProd15 = true
            }, 7000)
            setTimeout(function () {
                v.btnDaleeProd6 = true
            }, 17000)
        },
        btnProd15() {
            this.nowDateTime.dateTime8 = this.dateTimeNow()
            this.answProd15= true
            this.disBtnProd15 = true
            this.showTypingTxt()
            this.showMesProd16()
        },
        showMesProd16() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesProd16 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 4500)
            setTimeout(function () {
                v.isShow = false
            }, 6500)
            setTimeout(function () {
                v.mesProd17 = true
            }, 7000)
            setTimeout(function () {
                v.btnDaleeProd7 = true
            }, 17000)
        },
        btnProd17() {
            if (!this.zayavka) {
                this.nowDateTime.dateTime9 = this.dateTimeNow()
                this.answProd17= true
                this.disBtnProd17 = true
                this.showTypingTxt()
                if (this.userData.about_chat) {
                    this.showMesClientChat()
                } else if (this.userTestData) {
                    this.showMesTest()
                } else {
                    this.showMesReqProd()
                }
            } else {
                this.nowDateTime.dateTime18 = this.dateTimeNow()
                this.answProd17= true
                this.disBtnProd17 = true
                this.showTypingTxt()
                this.showMesEndLeedbot()
            }
        },
        showMesEndLeedbot() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesEndLeedbot = true
            }, 3500)
        },
        showMesClientChat() {
            let v = this
            this.nowDateTime.dateTime10 = this.dateTimeNow()
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesClientChat = true
            }, 3500)
        },
        btnChatSkip() {
            this.nowDateTime.dateTime11 = this.dateTimeNow()
            this.answChat= true
            this.disBtnChatSkip = true
            this.disBtnChat = true
            this.answerClientChat = 'Пропустить'
            this.showTypingTxt()
            if (this.userTestData) {
                this.showMesTest()
            } else {
                this.showMesReqProd()
            }
        },
        btnChat() {
            this.nowDateTime.dateTime11 = this.dateTimeNow()
            this.answChat= true
            this.disBtnChatSkip = true
            this.disBtnChat = true
            this.answerClientChat = 'Оставить заявку'
            this.showTypingTxt()
            this.showMesSendChat()
        },
        showMesSendChat() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesSendChat = true
            }, 3500)
        },
        btnSendChat() {
            this.$v.clientData.name.$touch()
            this.$v.clientData.phone_whatsapp.$touch() || this.$v.clientData.phone_viber.$touch()
            if (!this.$v.clientData.name.$invalid) {
                if(!this.$v.clientData.phone_whatsapp.$invalid || !this.$v.clientData.phone_viber.$invalid) {
                    this.disBtnSendChat = true
                    this.disInpName = true
                    this.disInpViber = true
                    this.disInpWhatsapp = true
                    // this.disInpTelegram = true
                    this.clientData.req = 'Заявка на клиентский чат'
                    this.createProspect(this.clientData)
                        .then((response) => {
                            if (response.data && response.data.success) {
                                this.errors = {}
                                this.addNotification({
                                    show: true,
                                    text: response.data.message,
                                    color: 'success'
                                })
                                this.showTypingTxt()
                                this.showMesProd18()
                                this.zayavka = true
                            }
                        })
                        .catch((error) => {
                            switch (error.response.status) {
                                case 422:
                                    this.loading = false
                                    this.addNotification({
                                        show: true,
                                        text: error.response.data.message,
                                        color: 'error'
                                    })
                                    this.errors = error.response.data.errors
                                    break
                                case 500:
                                    this.loading = false
                                    this.addNotification({
                                        show: true,
                                        text: error.response.data.message,
                                        color: 'error'
                                    })
                                    this.errors = error.response.data.errors
                                    break
                                default:
                                    this.loading = false
                                    this.addNotification({
                                        show: true,
                                        text: error.response.data.message,
                                        color: 'error'
                                    })
                                    this.errors = error.response.data.errors
                                    break
                            }
                        })
                } else {
                    this.addNotification({
                        show: true,
                        text: 'Ошибка валидации',
                    })
                }
            } else {
                this.addNotification({
                    show: true,
                    text: 'Ошибка валидации',
                })
            }
        },
        showMesTest() {
            let v = this
            this.nowDateTime.dateTime12 = this.dateTimeNow()
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesTest = true
            }, 3500)
        },
        btnTestSkip() {
            this.nowDateTime.dateTime13 = this.dateTimeNow()
            this.answTest= true
            this.disBtnTestSkip = true
            this.disBtnTest = true
            this.answerTest = 'Пропустить'
            this.showTypingTxt()
            this.showMesReqProd()
        },
        btnTest() {
            this.nowDateTime.dateTime13 = this.dateTimeNow()
            this.answTest= true
            this.disBtnTestSkip = true
            this.disBtnTest = true
            this.answerTest = 'Оставить заявку'
            this.showTypingTxt()
            this.showMesSendTest()
        },
        showMesSendTest() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesSendTest = true
            }, 3500)
        },
        btnSendTest() {
            this.$v.clientData.name.$touch()
            this.$v.clientData.phone_whatsapp.$touch() || this.$v.clientData.phone_viber.$touch()
            if (!this.$v.clientData.name.$invalid) {
                if (!this.$v.clientData.phone_whatsapp.$invalid || !this.$v.clientData.phone_viber.$invalid) {
                    this.disBtnSendTest = true
                    this.disInpName = true
                    this.disInpWhatsapp = true
                    this.disInpViber = true
                    // this.disInpTelegram = true
                    this.clientData.req = 'Заявка на онлайн-тест'
                    this.createProspect(this.clientData)
                        .then((response) => {
                            if (response.data && response.data.success) {
                                this.errors = {}
                                this.addNotification({
                                    show: true,
                                    text: response.data.message,
                                    color: 'success'
                                })
                                this.showTypingTxt()
                                this.showMesProd18()
                                this.zayavka = true
                            }
                        })
                        .catch((error) => {
                            switch (error.response.status) {
                                case 422:
                                    this.loading = false
                                    this.addNotification({
                                        show: true,
                                        text: error.response.data.message,
                                        color: 'error'
                                    })
                                    this.errors = error.response.data.errors
                                    break
                                case 500:
                                    this.loading = false
                                    this.addNotification({
                                        show: true,
                                        text: error.response.data.message,
                                        color: 'error'
                                    })
                                    this.errors = error.response.data.errors
                                    break
                                default:
                                    this.loading = false
                                    this.addNotification({
                                        show: true,
                                        text: error.response.data.message,
                                        color: 'error'
                                    })
                                    this.errors = error.response.data.errors
                                    break
                            }
                        })
                } else {
                    this.addNotification({
                        show: true,
                        text: 'Ошибка валидации',
                    })
                }
            } else {
                this.addNotification({
                    show: true,
                    text: 'Ошибка валидации',
                })
            }
        },
        showMesReqProd() {
            let v = this
            this.nowDateTime.dateTime14 = this.dateTimeNow()
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesReqProd = true
            }, 3500)
        },
        btnReqProd() {
            this.answReqProd = true
            this.disBtnReqProd = true
            this.disBtnSkipProd = true
            this.answerReqProd = 'Оставить заявку'
            this.showTypingTxt()
            this.showMesSendProd()
        },
        btnSkipProd() {
            // this.answReqProd = true
            this.disBtnReqProd = true
            this.disBtnSkipProd = true
            // this.answerReqProd = 'Расскажи как можно заработать'
            this.clientData.branch = 'бизнес'
            this.mesFirst = false
            this.mesSecond = false
            this.mesProd1 = false
            this.mesProd2 = false
            this.mesProd3 = false
            this.answProd3 = false
            this.mesProd4 = false
            this.mesProd5 = false
            this.answProd5 = false
            this.mesProd6 = false
            this.mesProd7 = false
            this.answProd7 = false
            this.mesProd8 = false
            this.mesProd9 = false
            this.answProd9 = false
            this.mesProd10 = false
            this.mesProd11 = false
            this.mesProd12 = false
            this.mesProd13 = false
            this.answProd13 = false
            this.mesProd14 = false
            this.mesProd15 = false
            this.answProd15 = false
            this.mesProd16 = false
            this.mesProd17 = false
            this.answProd17 = false
            this.mesClientChat = false
            this.answChat = false
            this.mesSendChat = false
            this.mesTest = false
            this.answTest = false
            this.mesSendTest = false
            this.mesReqProd = false
            this.mesSendProd = false
            this.mesProd18 = false
            this.mesProd19 = false
            this.answProd19 = false
            this.mesProd20 = false
            this.showTypingTxt()
            this.showMesBiz1()
        },
        showMesSendProd() {
            let v = this
            this.nowDateTime.dateTime15 = this.dateTimeNow()
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesSendProd = true
            }, 3500)
        },
        btnSendProd() {
            this.$v.clientData.name.$touch()
            this.$v.clientData.phone_whatsapp.$touch() || this.$v.clientData.phone_viber.$touch()
            if (!this.$v.clientData.name.$invalid) {
                if (!this.$v.clientData.phone_whatsapp.$invalid || !this.$v.clientData.phone_viber.$invalid) {
                    this.disBtnSendProd = true
                    this.disInpName = true
                    this.disInpWhatsapp = true
                    this.disInpViber = true
                    // this.disInpTelegram = true
                    this.clientData.req = 'Заявка на продукт'
                    this.createProspect(this.clientData)
                        .then((response) => {
                            if (response.data && response.data.success) {
                                this.errors = {}
                                this.addNotification({
                                    show: true,
                                    text: response.data.message,
                                    color: 'success'
                                })
                                this.showTypingTxt()
                                this.showMesProd18()
                                this.zayavka = true
                            }
                        })
                        .catch((error) => {
                            switch (error.response.status) {
                                case 422:
                                    this.loading = false
                                    this.addNotification({
                                        show: true,
                                        text: error.response.data.message,
                                        color: 'error'
                                    })
                                    this.errors = error.response.data.errors
                                    break
                                case 500:
                                    this.loading = false
                                    this.addNotification({
                                        show: true,
                                        text: error.response.data.message,
                                        color: 'error'
                                    })
                                    this.errors = error.response.data.errors
                                    break
                                default:
                                    this.loading = false
                                    this.addNotification({
                                        show: true,
                                        text: error.response.data.message,
                                        color: 'error'
                                    })
                                    this.errors = error.response.data.errors
                                    break
                            }
                        })
                } else {
                    this.addNotification({
                        show: true,
                        text: 'Ошибка валидации',
                    })
                }
            } else {
                this.addNotification({
                    show: true,
                    text: 'Ошибка валидации',
                })
            }
        },
        showMesProd18() {
            let v = this
            this.nowDateTime.dateTime16 = this.dateTimeNow()
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesProd18 = true
            }, 3500)
            setTimeout(function () {
                v.isShow = true
            }, 4500)
            setTimeout(function () {
                v.isShow = false
            }, 6500)
            setTimeout(function () {
                v.mesProd19 = true
            }, 7000)
        },
        btnProd19Skip() {
            this.nowDateTime.dateTime17= this.dateTimeNow()
            this.disBtnProd19Skip = true
            this.disBtnProd19 = true
            this.answProd19 = true
            this.answerProd19 = 'Пропустить'
            this.showTypingTxt()
            this.showMesProd20()
        },
        showMesProd20() {
            let v = this
            setTimeout(function () {
                v.isShow = false
            }, 3000)
            setTimeout(function () {
                v.mesProd20 = true
            }, 3500)
        },
        btnProd19() {
            this.nowDateTime.dateTime17= this.dateTimeNow()
            this.disBtnProd19Skip = true
            this.disBtnProd19 = true
            this.answProd19 = true
            this.answerProd19 = 'Расскажи как заработать'
            this.mesFirst = false
            this.mesSecond = false
            this.answBizProd = false
            this.mesProd1 = false
            this.mesProd2 = false
            this.mesProd3 = false
            this.answProd3 = false
            this.mesProd4 = false
            this.mesProd5 = false
            this.answProd5 = false
            this.mesProd6 = false
            this.mesProd7 = false
            this.answProd7 = false
            this.mesProd8 = false
            this.mesProd9 = false
            this.answProd9 = false
            this.mesProd10 = false
            this.mesProd11 = false
            this.mesProd12 = false
            this.mesProd13 = false
            this.answProd13 = false
            this.mesProd14 = false
            this.mesProd15 = false
            this.answProd15 = false
            this.mesProd16 = false
            this.mesProd17 = false
            this.answProd17 = false
            this.mesClientChat = false
            this.answChat = false
            this.mesSendChat = false
            this.mesTest = false
            this.answTest = false
            this.mesSendTest = false
            this.mesReqProd = false
            this.answReqProd = false
            this.mesSendProd = false
            this.mesProd18 = false
            this.mesProd19 = false
            this.answProd19 = false
            this.mesProd20 = false
            this.showTypingTxt()
            this.showMesBiz1()
        },
    },
    mounted() {
        this.showUser({user: this.lr_number, inst: this.inst})
        this.ifUserTest(this.lr_number)
        this.loading = false

        this.showTyping()

        this.clientData.partner = this.$route.query.partner
        this.clientData.inst = this.$route.query.inst
    },
    updated() {
        this.scrollToMe()
    },
    validations: {
        clientData: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30),
                alpha
            },
            age: {
                required,
                numeric,
                minLength: minLength(1),
                maxLength: maxLength(2)
            },
            income_want: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(191)
            },
            phone: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(20)
            },
            phone_whatsapp: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(20)
            },
            phone_viber: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(20)
            },
            // telegram: {
            //     required
            // }
        }
    }
}
</script>

<style lang="sass">
.chat-list p
    font-size: 16px

.chat-list
    width: 100%
    height: 100%
    box-sizing: border-box
    display: flex
    flex-direction: column
    justify-content: flex-end
    padding: 40px 11px

.chat-item
    display: flex
    margin-bottom: 15px

.chat-item.item-user
    width: 100%
    align-self: flex-end
    flex-direction: row-reverse
    margin-top: 40px
    margin-bottom: 40px

.chat-item p
    margin-bottom: 0

.chat-item ul
    list-style: none
    padding: 0
    font-size: 16px

.chat-item ul li i
    top: -2px

.chat-avatar
    width: 32px
    height: 32px
    margin-top: 20px
    margin-right: 10px
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    background-color: #999999
    flex-shrink: 0
    border-radius: 50%

.chat-avatar img
    width: 100%
    border-radius: 50%

.chat-block
    min-width: 60%
    max-width: 90%

.chat-date
    opacity: 0.5
    color: #252525
    font-size: 14px
    font-weight: 400
    margin-bottom: 5px

.chat-messages
    width: 100%
    display: flex
    flex-direction: column
    align-items: flex-start

.chat-message
    display: inline-block
    background-color: rgb(248, 248, 248)
    padding: 15px 20px
    color: rgb(37, 37, 37)
    font-size: 14px
    font-weight: 400
    line-height: 1.5
    margin-bottom: 5px
    min-width: 250px

.chat-message:last-of-type
    border-bottom-left-radius: 10px
    border-bottom-right-radius: 10px

.chat-message:first-of-type
    border-top-left-radius: 10px
    border-top-right-radius: 10px

.chat-input
    margin-bottom: 10px

.chat-controls
    display: flex
    margin-top: 10px
    align-items: center
    flex-wrap: wrap

.chat-controls-end
    display: flex
    margin-top: 10px
    align-items: center
    flex-wrap: wrap
    justify-content: flex-start

.chat-btn
    margin-bottom: 10px !important
    background-color: #4caf50 !important

.chat-btn.btn-skip
    background-color: gray !important

.chat-btn.btn-send
    margin-left: auto

.item-user .chat-avatar-user
    background-image: url("/img/leedbot/user_avatar.png")
    margin-left: 10px
    margin-right: 0
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    background-color: #999999
    flex-shrink: 0
    border-radius: 50%
    width: 32px
    height: 32px
    margin-top: 20px

.item-user .chat-date
    text-align: right

.item-user .chat-messages
    align-items: flex-end

.chat-typing
    font-size: 12px
    color: #999999
    display: flex
    align-items: baseline
    margin-left: 45px
    opacity: 0
    transform: translateY(5px)

.chat-typing.show
    opacity: 1
    transform: translateY(0)
    transition: 0.5s

.chat-typing-animate
    width: 27px
    overflow: hidden
    margin-left: 5px

.chat-typing-box
    display: flex

@keyframes typing
    0%
        transform: translateX(-30%)
    100%
        transform: translateX(0px)

@-webkit-keyframes typing
    0%
        transform: translateX(-30%)
    100%
        transform: translateX(0px)

.chat-typing.show .chat-typing-box
    -webkit-animation: typing 1s infinite
    animation: typing 1s infinite

.chat-typing-box img
    width: 12px
    height: 12px

.fade-enter-active, .fade-leave-active
    transition: opacity 1s

.fade-enter, .fade-leave-to
    opacity: 0

@media(max-width: 600px)
    .main-col
        padding-left: 0
        padding-right: 0

    .chat-list
        padding-left: 0
        padding-right: 0

@media(max-width: 480px)
    .video-js .vjs-big-play-button
        top: 40% !important
        left: 40% !important

@media(max-width: 350px)
    .video-js .vjs-big-play-button
        top: 36% !important
        left: 36% !important

</style>